import React, { useState } from 'react'
import { FormInput, } from 'shards-react';
import { strings } from '../../localization';
import RestaurantFoodItemRow from './RestaurantFoodItemRow';

function RestaurantFoodItemList(props) {
    const {
        foodItems,
        foodItemData,
        handleEditBtnPressed,
        manageFoodQuantity,
    } = props;

    // states
    const [searchedFoodName, setSearchedFoodName] = useState("");
    const [items, setItems] = useState(props.foodItems);
    const [itemData, setItemData] = useState(props.foodItems);

    const searchItemFromFoodItems = (value) => {
        let filteredData = itemData;
        if (searchedFoodName.length > 0) {
            filteredData = itemData.filter((item, index) => item['data']['name'].toLowerCase().includes(value.toLowerCase()))
        }
        console.log('searchItemFromFoodItems func filteredData', filteredData);
        setItems([...filteredData]);
    }

    console.log('items', items);
    return (
        <>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
                <FormInput
                    type="text"
                    value={searchedFoodName}
                    placeholder={strings.search_by_food_name}
                    onChange={e => {
                        setSearchedFoodName(e.target.value);
                        searchItemFromFoodItems(e.target.value);
                    }
                    }
                />
            </div>
            {
                items && items.length > 0 ? items.map((item, index) => {
                    console.log('foodItems in food item list :', item);
                    return (
                        <>
                            <RestaurantFoodItemRow
                                foodItem={item}
                                foodIndex={index}
                                handleEditBtnPressed={handleEditBtnPressed}
                                manageFoodQuantity={manageFoodQuantity}
                                isFromSummary={false}
                            />
                        </>
                    )
                })
                    : <div className='parent'>
                        <h4>{strings.no_data_available}</h4>
                    </div>
            }
        </>
    )
}

export default RestaurantFoodItemList