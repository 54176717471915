import React, { useEffect, useState } from 'react'
import { strings } from '../../localization';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Card, Button, CardBody, FormTextarea, FormInput } from "shards-react";
import './ManageFoodItemsDialog.css';

function FoodInstructionDialog({ showModal, setShowModal, foodItem, foodIndex, onSaveButtonPressed, isFromFoodSelection, isSaveBtnLoading, showSaveButton }) {

    const quantity = ['1', '2', '3', '4', '5'];
    const [foodInstruction, setFoodInstruction] = useState('');
    const [foodItemData, setFoodItemData] = useState('');

    const handleSaveButtonClicked = () => {
        let itemData = { ...foodItemData };
        if (foodInstruction) {
            itemData['note'] = foodInstruction;
        }
        onSaveButtonPressed(itemData, foodIndex);
    }

    useEffect(() => {
        let data = { ...foodItem };
        if (data) {
            setFoodItemData(data);
            setFoodInstruction(isFromFoodSelection ? data['data']['note'] : data['note'])
        }
    }, [])

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <FormTextarea
                        value={foodInstruction}
                        placeHolder={strings.enter_food_instruction}
                        className="note"
                        size="lg"
                        rows="4"
                        onChange={e =>
                            setFoodInstruction(e.target.value)
                        }
                    />
                </div>
            </Modal.Body>
            {
                showSaveButton &&
                <Modal.Footer>
                    <Button
                        theme="primary"
                        onClick={handleSaveButtonClicked}
                        className="saveBtn"
                    >
                        {
                            isSaveBtnLoading
                                ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                : strings.save.toUpperCase()
                        }
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    )
}

export default FoodInstructionDialog