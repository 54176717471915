import React from "react";

import { strings } from "./../../localization";
import { Language } from "./../../config";

import Autosuggest from "react-autosuggest";

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(value, filters) {
  // value = stateValue + value;

  value = value.split(",").reverse()[0];

  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === "") {
    return [];
  }

  const regex = new RegExp("^" + escapedValue, "i");

  return filters.filter(language => regex.test(language.name));
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.name}</span>;
}

class CustomSearchFilter extends React.Component {
  state = {
    value: "",
    suggestions: [],
    filters: []
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);
  }

  componentDidMount() {
    // console.log(this.props);
    this.setState({
      filters: this.props.filters
    });
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onKeyDown = e => {
    if (e.keyCode === 8 && this.state.value === "") {
      this.props.getData();
    }
  };

  checkKeyExist = value => {
    // alert(value.includes(","));
    // alert(!value.includes(","));
    // alert(!value.includes(":"));
    if (!value.includes(",") && !value.includes(":")) {
      return this.state.filters.find(element => element.name === value);
    } else {
      return true;
    }
  };

  onKeyPress = event => {
    let stateValue = this.state.value;

    var code = event.keyCode || event.which;

    if (
      stateValue.slice(-1) !== ":" &&
      stateValue.slice(-1) !== "," &&
      stateValue !== ""
    ) {
      var params = "";
      if (code === 13) {
        let status = this.checkKeyExist(stateValue);
        // alert(status);
        if (status) {
          if (stateValue.match(/^(\w+:\w+,)*\w+$/)) {
            stateValue = stateValue + ":";
          } else if (stateValue.match(/^(\w+:\w+,)*\w+:\w+$/)) {
            stateValue = stateValue + ",";
          } else {
            // alert("i am default");
            stateValue = stateValue + ",";
          }

          this.setState({
            value: stateValue
          });
        }

        var properties = stateValue.split(",");

        params = "&filter=true";
        let THIS = this;
        properties.forEach(function (property) {
          var name = property.split(":");
          if (name[0]) {
            let key = THIS.camelCase(name[0]);
            let value = name[1];

            if (!value) {
              value = key;
              key = THIS.camelCase(THIS.state.filters[0].name);
            }
            // alert(THIS.state.filters[0].name);

            switch (key) {
              case "address1":
                key = "addr1";
                break;

              case "address2":
                key = "addr2";
                break;

              case "total":
                key = "amount";
                break;

              case "rUT":
                key = "rutNumber";
                break;


              case "payment":
                key = "paymentMode";
                break;

              case "customerName":
                key = "name";
                break;

              case "number":
                key = "transactionNumber";
                break;

              case "orderNo":
                key = "orderID";
                break;

              default:
                break;
            }

            params += "&f_" + key + "=" + value + "*";
          }
        });

        // alert(params);

        this.props.getData(0, params);
      }
    }
  };

  camelCase = str => {
    return (str.slice(0, 1).toLowerCase() + str.slice(1))
      .replace(/([-_ ]){1,}/g, " ")
      .split(/[-_ ]/)
      .reduce((cur, acc) => {
        if (typeof acc[0] === "undefined") return cur;
        else return cur + acc[0].toUpperCase() + acc.substring(1);
      });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.state.filters)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  pageIncrement() {
    if (!this.state.next) {
      let skip = this.state.currentPage * this.state.limit;
      this.props.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.props.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    } else {
      this.setState({
        next: false
      });
    }
  }

  getSuggestionValue(suggestion) {
    // alert(suggestion);
    let final = "";

    if (this.state.value.includes(",")) {
      let stateValue = this.state.value;
      stateValue = stateValue.replace(/,[^,]+$/, "") + ",";
      final = stateValue + suggestion.name;
    } else {
      final = suggestion.name;
    }

    final = final + ":";

    return final;
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Buscar record",
      value,
      onChange: this.onChange,
      class: "form-control",
      onKeyPress: this.onKeyPress,
      onKeyDown: this.onKeyDown
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        getSuggestionValue={suggestion => this.getSuggestionValue(suggestion)}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}
export default CustomSearchFilter;
