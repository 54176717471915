import React, { Component } from 'react';
import { strings } from './../../localization';
import { Language, API_URL, LIMIT, roundWithTwoDecimals, getTotalPriceOfItem, getItemMainPrice, RESTAURANT } from './../../config';
import writtenNumber from 'written-number';
import { FormInput, FormTextarea, Button, Alert } from 'shards-react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import moment from 'moment';

export class Invoice extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);
    this.componentRef = React.createRef(null);
    this.state = {
      return: '',
      date: '',
      time: '',
      taxAmount: '',
      dataArray: [],
      organisation_info: [],
      taxPR: 19,
      displayType: '',
      dataArray: '',
      paymentMode: '',
      totalDiscount: '',
      type: '',
      displayDiscount: '',
      totalPaid: '',
      payable: '',
      return: '',
      tax: '',
      rutNumber: '',
      name: '',
      addr1: '',
      addr2: '',
      country: '',
      state: '',
      city: '',
      contact: '',
      razonRef: '',
      bDetails: '',
      view: '',
      printNumber: 0,
      loading: false,
      referenceTransactionType: '',
      netAmount: '',
      // propinaAmount: '0',
      // propinaChecked:null,
      // propinaPercentage: 0,
      showPermissionForPropina: null,
      propina: {},
      companyLogo: '',
      organisation_id: '',
      references: [],
      transportData: [],
      debitNoteReferencias: '',
      email: "",
      moduleName: ""
    };
  }

  formateNumber = value => {
    // return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (value.toString().includes('.')) {
      value = value.toString().replace('.', ',')
    }
    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.',);
  };
  updateData = async () => {
    console.log('function call....');
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let newTime = today.toLocaleTimeString();
    today = dd + '/' + mm + '/' + yyyy;

    const param = this.props;

    // if (!param.view) {
    //     BackHandler.addEventListener(
    //         'hardwareBackPress',
    //         this.handleBackButtonClick,
    //     );
    // } else {
    //     BackHandler.removeEventListener(
    //         'hardwareBackPress',
    //         this.handleBackButtonClick,
    //     );
    // }

    if (param !== undefined) {
      // console.log(`${param.bill_id}`);
      if (param.transaction_view !== undefined) {
        console.log('param.transaction_view....', param.transaction_view);

        this.setState({
          loading: true
        });

        // console.log('invoice');
        console.log(`In invoice url${API_URL}${param.type}/${param.bill_id}`);
        fetch(`${API_URL}${param.type}/${param.bill_id}`, {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        })
          // .then((res) => {
          //   console.log('======responce s', res);
          //   res.json();
          // })
          .then(res => res.json())
          .then(({ data }) => {
            console.log(
              '********************************************** data in invoice',
              data
            );
            if (data !== undefined) {
              let date = data.date ? data.date : '';
              let time = data.time ? data.time : '';

              if (param.newDate) {
                date = today;
                time = newTime;
              }

              let value = data.totalDiscount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              this.setState(
                {
                  dataArray: data.dataArray,
                  totalPaid: data.totalPaid,
                  isCancel: data.isCancel ? data.isCancel : 0,
                  creditnoteNumber: data.creditnoteNumber
                    ? data.creditnoteNumber
                    : 0,
                  payable: data.payable,
                  return: data.return,
                  paymentMode: data.paymentMode,
                  totalDiscount: data.totalDiscount,
                  rutNumber: data.rutNumber,
                  name: data.name,
                  addr1: data.addr1,
                  addr2: data.addr2,
                  country: data.country,
                  state: data.state,
                  city: data.city,
                  contact: data.contact,
                  razonRef: data.razonRef,
                  referenceTransactionType: data.referenceTransactionType,
                  netAmount: data.netAmount,
                  comment: data.comment,
                  bDetails: data.bDetails,
                  type: data.type,
                  view: data.view,
                  debitNoteReferencias: data.reference_text,
                  withoutTax: data.withoutTax,
                  displayDiscount: value,
                  loading: false,
                  propina: data.propina !== undefined && data.propina !== null ? data.propina : {
                    "percentage": 10,
                    "amount": 0,
                    "checked": false
                  },
                  // printNumber: param.printNumber, //change at 2024-10-09
                  printNumber: data.transactionNumber,
                  barcodeImage: param.barcodeImage,
                  barcodeHeight: param.barcodeHeight,
                  barcodeWidth: param.barcodeWidth,
                  pLeft: param.pLeft,
                  date: date,
                  time: time,
                  pWidth: param.pWidth,
                  references: param.references,
                  transportData: data.transportData,
                  email: data.email
                },
                () => {
                  this.afterStateUpdate();
                }
              );
            }
          })
          .catch(e => {
            // console.log('$$$$$$$$$$$$$$$$$$$');
            console.log('I am catch', e);
          });
        // param.transaction_view = undefined;
      }

      if (param.dataArray !== undefined) {
        console.log('param.dataArray....', param.dataArray);

        let value = param.totalDiscount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        console.log(param.date);
        console.log(param.time);

        this.setState(
          {
            dataArray: param.dataArray,
            paymentMode: param.paymentMode,
            totalDiscount: param.totalDiscount,
            type: param.type,
            displayDiscount: value,
            totalPaid: param.totalPaid,
            payable: param.total,
            return: param.return,
            date: param.date,
            time: param.time,
            tax: param.tax,
            rutNumber: param.rutNumber,
            name: param.name,
            addr1: param.addr1,
            addr2: param.addr2,
            country: param.country,
            state: param.state,
            city: param.city,
            contact: param.contact,
            comment: param.comment,
            bDetails: param.bDetails,
            view: param.view,
            withoutTax: param.withoutTax,
            printNumber: param.printNumber,
            barcodeImage: param.barcodeImage,
            barcodeHeight: param.barcodeHeight,
            barcodeWidth: param.barcodeWidth,
            pLeft: param.pLeft,
            pWidth: param.pWidth,
            // propinaAmount : param.propinaAmount, 
            // propinaChecked : param.propinaChecked,
            // propinaPercentage : param.propinaPercentage,
            propina: param.propina,
            showPermissionForPropina: param.showPermissionForPropina,
            references: param.references,
            debitNoteReferencias: param.debitNoteReferencias,
            transportData: param.transportData,
            email: param.email
          },
          () => {
            this.afterStateUpdate();
          }
        );

        // param.dataArray = undefined;
      }
      this.setState({
        moduleName: param.moduleName
      })
    }
  };
  afterStateUpdate = async () => {
    console.log('After updateData call.....');
    let finalTotal = 0;
    // alert('123123');
    let payable = this.state.payable.toString().replace(/\./g, '');
    let taxAmount = 0;

    if (this.state.tax) {
      taxAmount = (parseInt(payable) * this.state.taxPR) / 100;

      finalTotal = payable; // - taxAmount;
      finalTotal = Math.round(finalTotal);
      finalTotal = finalTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      console.log('inside after calculation tax ===', finalTotal);
    } else {
      finalTotal = this.state.payable;
      console.log('inside tax in else part===', finalTotal);
    }

    let type = this.state.type;

    if (type === 1) {
      type = strings.bills;
    } else if (type === 2) {
      type = strings.invoice;
    } else if (type === 3) {
      type = strings.credit_note;
    } else if (type === 4) {
      type = strings.delivery_note;
    } else if (type === 5) {
      type = strings.debit_note;
    } else if (type === 6) {
      type = strings.quote;
    }

    if (this.state.isCancel) {
      type = strings.credit_note;
    }

    this.setState({
      finalTotal,
      displayType: type
    });
  };

  async componentDidMount() {
    this.updateData();
    writtenNumber.defaults.lang = 'es';
    let organisation_info = await localStorage.getItem('organisation_info');
    let settings_info = await localStorage.getItem('settings_info');

    organisation_info = JSON.parse(organisation_info);
    settings_info = JSON.parse(settings_info);
    this.setState({
      organisation_info: organisation_info.data,
      organisation_id: organisation_info._id,
      verificationLinkOnPrint: settings_info.data.verificationLinkOnPrint
    },
      () => this.getOrganisationData()
    );
    let permissions = await localStorage.getItem('user_permissions');
    let parse = JSON.parse(permissions);
    let showPermission = parse.enable_propina;
    this.setState({ showPermissionForPropina: showPermission })
  }

  getOrganisationData() {
    console.log('oragnize data id', this.state.organisation_id);

    if (this.state.organisation_id) {
      fetch(`${API_URL}organisations/${this.state.organisation_id}`, {
        method: "GET",
        credentials: "include"
      })
        .then(res => res.json())
        .then(({ data }) => {
          console.log('organization api response is : ', data);
          this.setState({
            companyLogo: data.companyLogo
          });
        })
    } else {
      console.log('id is not found in organization api', this.state.organisation_info.id);
    }
  }

  render() {
    var subtotal = 0;
    var totalAfecto = '';
    var taxAmount = 0;
    var totalAll = 0;
    var neto = 0;
    let discountAmount = 0;
    console.log('this.state.type', this.state.type);
    return (
      <>
        <div
          ref={this.componentRef}
          className="print-invoice"
          style={{
            backgroundColor: '#fff',
            width: '100%',
            borderColor: '#999999',
            borderWidth: 1,
            padding: 20
          }}
        >
          <div>
            {this.state.loading && (
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}

            <p
              // style={{
              //   textAlign: 'center',
              //   fontSize: 15,
              //   opacity: 0.7
              // }}
              className='commonPrintTitleStyle commonPrinterFontStyles'
            >
              ----------------------------------------------
            </p>
            <p
              style={{
                paddingTop: 10,
                // fontSize: 15,
                // opacity: 0.7,
                // textAlign: 'center'
              }}
              className='commonPrintTitleStyle commonPrinterFontStyles'
            >
              R.U.T. :{' '}
              {this.state.organisation_info.hasOwnProperty('rutNumber')
                ? this.state.organisation_info['rutNumber']
                : null}
            </p>
            {/* <p
              style={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: 15,
                opacity: 0.7
              }}
            >
              {this.state.displayType}{' '}
              {this.state.withoutTax === true
                ? ' NO AFECTA O EXENTA ELECTRÓNICA '
                : ' ELECTRÓNICA '}{' '}
              No.{' '}
              {this.state.isCancel
                ? this.state.creditnoteNumber
                : this.state.printNumber}
            </p> */}
            <p
              style={{
                textTransform: 'uppercase',
                // fontSize: 15,
                // fontWeight: 500,
                // color: 'black',
                // textAlign: 'center',
              }}
              className='commonPrintTitleStyle commonPrinterFontStyles'
            >
              {this.state.displayType}{' '}
              {this.state.withoutTax === true
                ? ' NO AFECTA O EXENTA ELECTRÓNICA '
                : ' ELECTRÓNICA '}{' '}
            </p>
            <p
              style={{
                textTransform: 'uppercase',
                // textAlign: 'center',
                // fontSize: 15,
                // opacity: 0.7
              }}
              className='commonPrintTitleStyle commonPrinterFontStyles'
            >
              No.{' '}
              {this.state.isCancel
                ? this.state.creditnoteNumber
                : this.state.printNumber}
            </p>
            {/* <p
              style={{
                textAlign: 'center',
                fontSize: 15,
                opacity: 0.7
              }}
            >
              {this.state.organisation_info.hasOwnProperty('siiAddress')
                ? this.state.organisation_info['siiAddress']
                : null}
            </p> */}
            {/* {this.state.date && this.state.time ? (
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 15,
                  opacity: 0.7
                }}
              >
                Fecha : {this.state.date} {this.state.time}
              </p>
            ) : null} */}

            <p
              // style={{
              //   textAlign: 'center',
              //   fontSize: 15,
              //   opacity: 0.7
              // }}
              className='commonPrintTitleStyle commonPrinterFontStyles'
            >
              ----------------------------------------------
            </p>
            <p
              // style={{
              //   textAlign: 'center',
              //   fontSize: 15,
              //   opacity: 0.7
              // }}
              className='commonPrintTitleStyle commonPrinterFontStyles'
            >
              {this.state.organisation_info.hasOwnProperty('siiAddress')
                ? this.state.organisation_info['siiAddress']
                : null}
            </p>
          </div>

          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginTop: '10px'
            }}
          >
            {
              this.state.companyLogo !== undefined
              && this.state.companyLogo !== ''
              && <img
                style={{
                  width: "auto",
                  height: "100px",
                }}
                src={this.state.companyLogo}
                alt="EBOL"
              />
            }
          </div>

          {this.state.organisation_info.hasOwnProperty('name') ? (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ 
            //   fontSize: 15,
            //   opacity: 0.7,
            //   textAlign: 'left'
            // }}
            >
              {this.state.organisation_info['name']}
            </p>
          ) : null}

          {this.state.organisation_info.hasOwnProperty('address') ? (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{
            //   fontSize: 15,
            //   opacity: 0.7,
            //   textAlign: 'left'
            // }}
            >
              {this.state.organisation_info['address']}
            </p>
          ) : null}

          {this.state.organisation_info.hasOwnProperty('city') ||
            this.state.organisation_info.hasOwnProperty('state') ? (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ 
            //   fontSize: 15,
            //   opacity: 0.7,
            //   textAlign: 'left'
            // }}
            >
              {this.state.organisation_info['city'] +
                ', ' +
                this.state.organisation_info['state']}
            </p>
          ) : null}

          {this.state.organisation_info.hasOwnProperty('giro') ||
            this.state.organisation_info.hasOwnProperty('giro') ? (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
              style={{
                // textAlign: 'left',
                marginTop: 10,
                // fontSize: 15,
                // opacity: 0.7
              }}
            >
              GIRO : {this.state.organisation_info['giro']}
            </p>
          ) : null}

          {this.state.date && this.state.time ? (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{
            //   textAlign: 'left',
            //   fontSize: 15,
            //   opacity: 0.7
            // }}
            >
              Fecha : {this.state.date} {this.state.time}
            </p>
          ) : null}
          <p
            // style={{
            //   textAlign: 'center',
            //   fontSize: 15,
            //   opacity: 0.7
            // }}
            className='commonPrintTitleStyle commonPrinterFontStyles'
          >
            ----------------------------------------------
          </p>
          {this.state.rutNumber !== undefined &&
            this.state.rutNumber !== '' && (
              <p
                className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
              // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
              >
                RUT : {this.state.rutNumber}
              </p>
            )}
          {this.state.name !== undefined && this.state.name !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              NOMBRE : {this.state.name.toUpperCase()}
            </p>
          )}
          {this.state.addr1 !== undefined && this.state.addr1 !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              DIRECCION : {this.state.addr1.toUpperCase()}
            </p>
          )}
          {this.state.addr2 !== undefined && this.state.addr2 !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              COMUNA : {this.state.addr2.toUpperCase()}
            </p>
          )}
          {this.state.city !== undefined && this.state.city !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              CIUDAD : {this.state.city.toUpperCase()}
            </p>
          )}
          {this.state.state !== undefined && this.state.state !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              REGION : {this.state.state.toUpperCase()} {this.state.country ? this.state.country.toUpperCase() : ''}
            </p>
          )}
          {this.state.bDetails !== undefined && this.state.bDetails !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              GIRO : {this.state.bDetails.toUpperCase()}
            </p>
          )}
          {this.state.contact !== undefined && this.state.contact !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              NOMBRE DE CONTACTO : {this.state.contact.toUpperCase()}
            </p>
          )}


          {this.state.debitNoteReferencias !== undefined &&
            this.state.debitNoteReferencias !== '' && (
              <p
                className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
              // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
              >
                Referencias: {this.state.debitNoteReferencias}
              </p>
            )}
          {this.state.type === 3 &&
            this.state.razonRef !== undefined &&
            this.state.razonRef !== '' && (
              <p
                className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
              // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
              >
                Referencias: {this.state.razonRef}
              </p>
            )}
          {/* {this.state.rutNumber !== undefined && this.state.rutNumber !== '' && this.state.date && this.state.time ? (
              <p
                style={{
                  textAlign: 'left',
                  fontSize: 15,
                  opacity: 0.7
                }}
              >
                Fecha : {this.state.date} {this.state.time}
              </p>
            ) : null} */}

          {/* Transport section */}
          {/* Transport Section */}
          {this.state.type !== undefined &&
            this.state.type === 4 &&
            this.state.transportData !== undefined &&
            this.state.transportData.length > 0 &&
            <p
              className='commonPrintTitleStyle commonPrinterFontStyles'
            //  style={{ textAlign: 'center', fontSize: 15, opacity: 0.7, }}
            >
              -----------------------------------------------
            </p>
          }

          {this.state.type !== undefined &&
            this.state.type === 4 &&
            this.state.transportData !== undefined &&
            this.state.transportData.length > 0
            ? this.state.transportData.map((data, index) => {
              return (
                <>
                  <p
                    className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                  // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                  >
                    Tipo de traslado : {data.selectedTypeOfTransfer.label}
                  </p>
                  <p
                    className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                  // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                  >
                    Dirección de destino : {data.destinationAddress}
                  </p>
                  <p
                    className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                  // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                  >
                    Comuna de destino : {data.communityOfDestination}
                  </p>
                  <p
                    className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                  // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                  >
                    Ciudad de destino : {data.destinationCity}
                  </p>
                  {data.patente !== undefined && data.patente !== '' && (
                    <p
                      className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                    // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                    >
                      Patente : {data.patente}
                    </p>
                  )}
                  {data.transport !== undefined && data.transport !== '' && (
                    <p
                      className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                    // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                    >
                      RUT transporte : {data.transport}
                    </p>
                  )}
                  {data.rutDriver !== undefined && data.rutDriver !== '' && (
                    <p
                      className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                    // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                    >
                      RUT chofer : {data.rutDriver}
                    </p>
                  )}
                  {data.driverName !== undefined && data.driverName !== '' && (
                    <p
                      className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                    // style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}
                    >
                      Nombre chofer : {data.driverName}
                    </p>
                  )}
                </>
              )
            })
            : null
          }

          {(this.state.rutNumber !== undefined &&
            this.state.rutNumber !== '') ||
            (this.state.addr1 !== undefined && this.state.addr1 !== '') ||
            (this.state.addr2 !== undefined && this.state.addr2 !== '') ||
            (this.state.country !== undefined && this.state.country !== '') ||
            (this.state.city !== undefined && this.state.city !== '') ||
            (this.state.state !== undefined && this.state.state !== '') ||
            (this.state.phone !== undefined && this.state.phone !== '') ||
            (this.state.bDetails !== undefined && this.state.bDetails !== '') ||
            (this.state.name !== undefined && this.state.name !== '') ? (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              ----------------------------------------------
            </p>
          ) : null}
          {this.state.dataArray.length > 0
            ? this.state.dataArray.map((data, i) => {
              let quantity = data.quantity.toString().replace(/\,/g, '.');
              let total = parseFloat(quantity) * data.mainPrice;

              // console.log(total);

              // subtotal = subtotal + total; //commented at 2023-02-20
              subtotal += data.total
              subtotal = Math.round(roundWithTwoDecimals(subtotal));
              totalAfecto = subtotal - this.state.totalDiscount;
              totalAfecto = Math.round(roundWithTwoDecimals(totalAfecto));
              console.log('befar calculation: ' + neto);

              // /* CHANGES: Temporary for 2021-07-27, add FAWT while creating creditnote by API developer */
              // if (typeof data.FAWT !== 'undefined')
              //   neto = neto + data.FAWT;

              // /* CHANGES: Temporary for 2021-07-27, add iva while creating creditnote by API developer */
              // if (typeof data.iva !== 'undefined')
              //   taxAmount = taxAmount + data.iva;
              console.log(
                '================================================================referenceTransactionType',
                this.state.referenceTransactionType
              );

              // discount show logic
              if (data.discount !== undefined && data.discount !== null) {
                console.log('1111 in if data.discount', data.discount);
                if (this.state.type === 2 || this.state.type === 5 || this.state.type === 4 || this.state.type === 6 || this.state.type === 1) {
                  discountAmount = parseFloat((data.percent ? data.discount * data.quantity : data.discount) / 1.19).toFixed(2);
                } else {
                  discountAmount = data.percent ? data.discount * data.quantity : data.discount
                }
              } else {
                console.log('1111 in else data.discount', data.discount);
                discountAmount = 0;
              }
              console.log('1111 discountAmount', discountAmount);

              if (this.state.type === 2 || this.state.type === 5 || this.state.type === 4 || this.state.type === 6) {
                // neto = neto + data.FAWT; // this is commented on 2023-01-26
                neto = neto + (data.FAWT - discountAmount);
                taxAmount = Math.round(neto * 0.19);
              } else if (this.state.type === 3) {
                if (this.state.referenceTransactionType === 'invoice') {
                  neto = neto + data.FAWT;
                }
                taxAmount = taxAmount + data.iva;
                /*else {
                      neto = neto + data.total;
                    }*/
              } else if (this.state.type === 1) {
                neto = neto + (data.FAWT);
                taxAmount = taxAmount + data.iva;
              } else {
                neto = neto + data.total;
                taxAmount = taxAmount + data.iva;
              }
              // taxAmount = taxAmount + data.iva; //change at 2022-10-14
              console.log(data.FAWT);

              //totalAll = neto + taxAmount;
              //totalAll = Math.round(totalAll);
              totalAll += data.total;
              console.log(' after calculation : ' + neto);
              total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

              if (
                this.state.type === 3 &&
                this.state.referenceTransactionType === 'bill'
              ) {
                neto = this.state.netAmount;
              }
              return (
                <div key={i}>
                  <p
                    className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                  // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
                  >
                    {data.description}
                  </p>
                  <div className="d-flex">
                    <p
                      className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                    // style={{
                    //   fontSize: 15,
                    //   opacity: 0.7,
                    //   textAlign: 'left'
                    // }}
                    >
                      {data.quantity} {data.itemType} X{' '}
                      {/* Below line commented on 2024-08-05 by harshit
                            * Bill need to display same as invoice that's why
                            * Below code commented
                          */}
                      {/* {this.formateNumber(this.state.type === 4 ? Math.round(data.mainPrice) : data.mainPrice)} */}
                      {getItemMainPrice(data, this.state.type)}
                    </p>
                    <p
                      className='commonPrinterValueStyle commonPrinterFontStyles'
                    // style={{
                    //   marginLeft: 'auto',
                    //   fontSize: 15,
                    //   opacity: 0.7
                    // }}
                    >
                      {/* Below line commented on 2024-08-05 by harshit
                            * Bill need to display same as invoice that's why
                            * Below code commented
                          */}
                      {/* {this.state.type === 2 ||
                        (this.state.type === 3 &&
                          this.state.referenceTransactionType == 'invoice')
                        || this.state.type === 4
                        || this.state.type === 6
                        // ? data.FAWT
                        ? this.formateNumber(Math.round(data.FAWT))
                        : this.state.type === 5 ? this.formateNumber(parseFloat(data.FAWT)) : this.formateNumber(total)} */}
                      {getTotalPriceOfItem(data, this.state.type)}
                    </p>
                  </div>
                  {discountAmount !== undefined
                    && discountAmount !== null
                    && discountAmount > 0
                    && <div className="d-flex">
                      <p
                        className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                      // style={{
                      //   fontSize: 15,
                      //   opacity: 0.7,
                      //   textAlign: 'left'
                      // }}
                      >
                        DESCUENTO
                      </p>
                      <p
                        style={{
                          // marginLeft: 'auto',
                          // fontSize: 15,
                          // opacity: 0.7
                        }}
                        className='commonPrinterValueStyle commonPrinterFontStyles'
                      >
                        {'- '}{this.formateNumber(discountAmount)}
                      </p>
                    </div>
                  }
                </div>
              );
            })
            : null}

          {/* Below line commented on 2024-08-05 by harshit
              * Bill need to display same as invoice that's why
              * Below code commented
            */}
          {/* {this.state.type !== 2 && this.state.type !== 3 && this.state.type !== 5 && this.state.type !== 4 && this.state.type !== 6 && (
            <div className="d-flex">
              <p
                // style={{
                //   width: '60%',
                //   textAlign: 'right',
                //   fontSize: 15,
                //   opacity: 0.7
                // }}
                // style={{
                //   letterSpacing: 2
                // }}
                className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
              >
                SUBTOTAL
              </p>
              <p
                className='commonPrintTitleStyle commonPrinterFontStyles'
                style={{
                  // textAlign: 'center',
                  // fontSize: 15,
                  // opacity: 0.7,
                  marginLeft: 18
                }}
              >
                {' '}
                {'  '}$
              </p>
              <p
                className='commonPrinterValueStyle commonPrinterFontStyles'
              // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
              >
                {subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
          )} */}

          {/* This is commented on 2023-01-26 for single discount show */}
          {/* {this.state.type !== 2 
            && this.state.displayDiscount !== undefined
            && this.state.displayDiscount !== null
            && this.state.displayDiscount > 0
            && (
            <div className="d-flex">
              <p
                style={{
                  width: '60%',
                  textAlign: 'right',
                  fontSize: 15,
                  opacity: 0.7
                }}
              >
                DESCUENTO
              </p>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 15,
                  opacity: 0.7,
                  marginLeft: 18
                }}
              >
                {' '}
                {'  '}$
              </p>
              <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}>
                {this.state.displayDiscount}
              </p>
            </div>
          )} */}

          {/* Below line commented on 2024-08-05 by harshit
              * Bill need to display same as invoice that's why
              * Below code commented
            */}
          {/* {this.state.type !== 1 && ( */}
          <>
            {this.state.type !== 2 && this.state.type !== 3 && this.state.type !== 5 && this.state.type !== 4 && this.state.type !== 6 && this.state.type !== 1 && (
              <div className="d-flex">
                <p
                  // style={{
                  //   width: '60%',
                  //   textAlign: 'right',
                  //   fontSize: 15,
                  //   opacity: 0.7
                  // }}
                  className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
                >
                  TOTAL AFECTO
                </p>
                <p
                  style={{
                    // textAlign: 'center',
                    // fontSize: 15,
                    // opacity: 0.7,
                    marginLeft: 18
                  }}
                  className='commonPrintTitleStyle commonPrinterFontStyles'
                >
                  {' '}
                  {'  '}$
                </p>
                <p
                  // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
                  className='commonPrinterValueStyle commonPrinterFontStyles'
                >
                  {totalAfecto
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              </div>
            )}
            <div className="d-flex">
              <p
                // style={{
                //   width: '60%',
                //   textAlign: 'right',
                //   fontSize: 15,
                //   opacity: 0.7
                // }}
                className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
              >
                {this.state.withoutTax === true ? 'MONTO EXENTO ' : 'NETO '}
              </p>
              <p
                className='commonPrintTitleStyle commonPrinterFontStyles'
                style={{
                  // textAlign: 'center',
                  // fontSize: 15,
                  // opacity: 0.7,
                  marginLeft: 18
                }}
              >
                {' '}
                {'  '}$
              </p>
              <p
                // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
                className='commonPrinterValueStyle commonPrinterFontStyles'
              >
                {Math.round(neto)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
            <div className="d-flex">
              <p
                // style={{
                //   width: '60%',
                //   textAlign: 'right',
                //   fontSize: 15,
                //   opacity: 0.7
                // }}
                className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
              >
                TOTAL IVA (19 %)
              </p>
              <p
                className='commonPrintTitleStyle commonPrinterFontStyles'
                style={{
                  // textAlign: 'center',
                  // fontSize: 15,
                  // opacity: 0.7,
                  marginLeft: 18
                }}
              >
                {' '}
                {'  '}$
              </p>
              <p
                className='commonPrinterValueStyle commonPrinterFontStyles'
              // style={{marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
              >
                {Math.round(taxAmount)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
          </>
          {/* )} */}

          <div className="d-flex">
            <p
              // style={{
              //   width: '60%',
              //   textAlign: 'right',
              //   fontSize: 15,
              //   opacity: 0.7
              // }}
              className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
            >
              TOTAL
            </p>
            <p
              className='commonPrintTitleStyle commonPrinterFontStyles'
              style={{
                // textAlign: 'center',
                // fontSize: 15,
                // opacity: 0.7,
                marginLeft: 18
              }}
            >
              {' '}
              {'  '}$
            </p>
            <p
              className='commonPrinterValueStyle commonPrinterFontStyles'
            // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
            >
              {Math.round(totalAll)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </p>
          </div>



          {typeof (this.state.propina) !== 'undefined' && typeof (this.state.propina.checked) !== 'undefined' && this.state.showPermissionForPropina === true && this.state.propina.checked === true &&
            <>
              <div className="d-flex">
                <p
                  className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
                // style={{
                //   width: '60%',
                //   textAlign: 'right',
                //   fontSize: 15,
                //   opacity: 0.7
                // }}
                >
                  PROPINA SUGERIDA {this.state.propina.percentage}%
                </p>
                <p
                  className='commonPrintTitleStyle commonPrinterFontStyles'
                  style={{
                    // textAlign: 'center',
                    // fontSize: 15,
                    // opacity: 0.7,
                    marginLeft: 18
                  }}
                >
                  {' '}
                  {'  '}$
                </p>
                <p
                  className='commonPrinterValueStyle commonPrinterFontStyles'
                // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
                >
                  {this.state.propina !== undefined && this.state.propina.amount === undefined ? 0 : this.state.propina.amount.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              </div>

              <div className="d-flex">
                <p
                  className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
                // style={{
                //   width: '60%',
                //   textAlign: 'right',
                //   fontSize: 15,
                //   opacity: 0.7
                // }}
                >
                  TOTAL + PROPINA
                </p>
                <p
                  className='commonPrintTitleStyle commonPrinterFontStyles'
                  style={{
                    // textAlign: 'center',
                    // fontSize: 15,
                    // opacity: 0.7,
                    marginLeft: 18
                  }}
                >
                  {' '}
                  {'  '}$
                </p>
                <p
                  className='commonPrinterValueStyle commonPrinterFontStyles'
                // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
                >
                  {(Math.round(totalAll) + (this.state.propina && this.state.propina.amount ? this.state.propina.amount : 0))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              </div>
            </>
          }



          {this.state.type && this.state.type !== 3 && this.state.type !== 6 &&
            <div className="d-flex">
              <p
                className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
                style={{
                  // width: '60%',
                  // textAlign: 'right',
                  textTransform: 'uppercase',
                  // fontSize: 15,
                  // opacity: 0.7
                }}
              >
                {this.state.paymentMode}
              </p>
              <p
                className='commonPrintTitleStyle commonPrinterFontStyles'
                style={{
                  // textAlign: 'center',
                  // fontSize: 15,
                  // opacity: 0.7,
                  marginLeft: 18
                }}
              >
                {' '}
                {'  '}$
              </p>
              <p
                className='commonPrinterValueStyle commonPrinterFontStyles'
              // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
              >
                {/* commented at 2023-03-29 for resolving issue of amount proper not showing when add */}
                {/* {this.state.totalPaid
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} */}
                {(Math.round(parseFloat(this.state.totalPaid) + (this.state.propina !== undefined && this.state.propina.amount !== undefined ? this.state.propina.amount : 0)))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
          }
          {this.state.type && this.state.type !== 3 && this.state.type !== 6 &&
            <div className="d-flex">
              <p
                // style={{
                //   width: '60%',
                //   textAlign: 'right',
                //   fontSize: 15,
                //   opacity: 0.7
                // }}
                className='commonPrinterSecondTitleStyle commonPrinterFontStyles'
              >
                VUELTO
              </p>
              <p
                className='commonPrintTitleStyle commonPrinterFontStyles'
                style={{
                  // textAlign: 'center',
                  // fontSize: 15,
                  // opacity: 0.7,
                  marginLeft: 18
                }}
              >
                {' '}
                {'  '}$
              </p>
              <p
                className='commonPrinterValueStyle commonPrinterFontStyles'
              // style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7 }}
              >
                {this.state.return}
              </p>
            </div>
          }
          <p
            className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            style={{
              // textAlign: 'left',
              marginTop: 5,
              // fontSize: 15,
              // opacity: 0.7
            }}
          >
            son : {writtenNumber(totalAll)}
          </p>
          {/* Below line commented on 2024-08-05 by harshit
              * Bill need to display same as invoice that's why
              * Below code commented
            */}
          {/* {this.state.type === 1 && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
              style={{
                // textAlign: 'left',
                marginTop: 5,
                // fontSize: 15,
                // opacity: 0.7
              }}
            >
              El IVA de esta boleta es : $
              {Math.round(taxAmount)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </p>
          )} */}
          {this.state.comment !== undefined && this.state.comment !== '' && (
            <p
              className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
            // style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}
            >
              {strings.comment} : {this.state.comment}
            </p>
          )}
          {this.state.references.length > 0 && (
            <>
              <p
                className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                style={{
                  // textAlign: 'left',
                  marginTop: 5,
                  // fontSize: 15,
                  // opacity: 0.7
                }}
              >
                Referencias:
              </p>
              {this.state.references.map(item => {
                return (
                  <>
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'flex' }}>
                        <p
                          className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                        // style={{
                        //   textAlign: 'left',
                        //   fontSize: 15,
                        //   opacity: 0.7
                        // }}
                        >
                          {`-`}
                        </p>
                      </div>
                      <p
                        className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                        style={{
                          // textAlign: 'left',
                          // fontSize: 15,
                          // opacity: 0.7,
                          marginLeft: '1rem'
                        }}
                      >
                        Tipo doc: {item.tipoDoc.label}
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p
                        className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                        style={{
                          // textAlign: 'left',
                          // fontSize: 15,
                          // opacity: 0.7,
                          marginLeft: '1.5rem'
                        }}
                      >
                        {'   '}N° doc: {item.nDoc}
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p
                        className='commonPrintLeftAlignTitleStyle commonPrinterFontStyles'
                        style={{
                          // textAlign: 'left',
                          // fontSize: 15,
                          // opacity: 0.7,
                          marginLeft: '1.5rem'
                        }}
                      >
                        {'   '}Fecha doc: {moment(item.fechaDoc).format("DD/MM/YYYY")}
                        {/* {'   '}Fecha doc: {item.fechaDoc.toLocaleDateString()} */}
                      </p>
                    </div>
                  </>
                );
              })}
            </>
          )}
          {this.state.barcodeImage && this.state.barcodeImage !== '' &&
            <div style={{ alignItems: 'center', padding: 'auto', marginTop: 10 }}>
              <img
                src={`data:image/jpeg;base64,${this.state.barcodeImage}`}
                style={{
                  height: this.state.barcodeHeight,
                  width: this.state.barcodeWidth
                }}
              />
            </div>
          }
          {this.state.type !== undefined && this.state.type !== 6 &&
            <p
              style={{
                paddingTop: 10,
                // fontSize: 15,
                // opacity: 0.7,
                // textAlign: 'center'
              }}
              className='commonPrintTitleStyle commonPrinterFontStyles'
            >
              Timbre Electrónico SII RES. 115 DE 2005
            </p>
          }
          <p
            className='commonPrintTitleStyle commonPrinterFontStyles'
            style={{
              paddingTop: 10,
              // fontSize: 15,
              // opacity: 0.7,
              // textAlign: 'center',
              textDecoration: 'underline'
            }}
          >
            <a target="_blank" className='commonPrintTitleStyle commonPrinterFontStyles' href="https://www.ebol.cl/">www.ebol.cl</a>
          </p>
          {this.state.verificationLinkOnPrint !== undefined &&
            this.state.verificationLinkOnPrint !== '' && (
              <p
                className='commonPrintTitleStyle commonPrinterFontStyles'
                style={{
                  paddingTop: 10,
                  // fontSize: 15,
                  // opacity: 0.7,
                  // textAlign: 'center'
                }}
              >
                Verifique Document : {this.state.verificationLinkOnPrint}
              </p>
            )}
        </div>

        {!this.props.showNewButton && (
          <div className="mt-4 d-flex justify-content-center">
            <ReactToPrint content={() => this.componentRef.current}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button
                    pill
                    size="lg"
                    className="mt-4 mr-2"
                    style={{
                      width: '40%',
                      alignSelf: 'center'
                    }}
                    onClick={handlePrint}
                  >
                    {' '}
                    {strings.print}
                  </Button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </div>
        )}

        {this.props.showNewButton && (
          <div className="mt-4 d-flex justify-content-between">
            <ReactToPrint content={() => this.componentRef.current}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button
                    pill
                    size="lg"
                    className="mt-4 mr-2"
                    style={{
                      width: '40%',
                      alignSelf: 'center'
                    }}
                    onClick={handlePrint}
                  >
                    {' '}
                    {strings.print}
                  </Button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
            <Button
              pill
              size="lg"
              className="mt-4"
              style={{
                width: '40%',
                alignSelf: 'center'
              }}
              onClick={() => {
                if (this.state.moduleName && this.state.moduleName === RESTAURANT) {
                  this.props.closeSideBar();
                } else {
                  this.props.closeAll()
                }
              }}
            >
              {strings.new}
            </Button>
          </div>
        )}
      </>
    );
  }
}

export default Invoice;
