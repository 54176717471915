import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'shards-react'
import Select, { components } from 'react-select';
import PageTitle from '../../components/common/PageTitle'
import { strings } from '../../localization'
import './Restaurant.css'
import { API_URL } from '../../config'
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Tabs, Tab } from 'react-bootstrap';
import TableList from '../../components/TableList';
import RestaurantOrderSummary from './RestaurantOrderSummary';
import swal from 'sweetalert2';
window.Swal = swal;


const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 15,
        width: 15,
    },
});

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: '40px',
    }),
    input: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.bgColor) }),
};


function RestaurantTables(props) {
    // Table  states
    const [tableList, setTableList] = useState([]);
    const [spaceData, setSpaceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSidebarShow, setIsSidebarShow] = useState(false);
    const [selectedTableId, setSelectedTableId] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState(null);



    const getTableList = async () => {
        let statusCode = 0;
        fetch(`${API_URL}restaurant-space/all-table`, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((res) => {
            console.log('response of space list', res)
            if (statusCode == 200) {
                let result = res['result'];
                let data = result && result['data'] ? result['data'] : [];
                let tableArr = [];
                console.log('data', data);
                if (data !== undefined && data !== null && data.length > 0) {
                    if (data.length > 1) {
                        tableArr = [];
                    } else {
                        tableArr = [...data[0].data.tableIDs];
                    }
                    console.log('tableArr', tableArr);
                    setTableList([...tableArr]);
                    setSpaceData([...data]);
                } else {
                    setTableList([]);
                    setSpaceData([]);
                }
            }
            setIsLoading(false);
        }).catch((err) => {
            console.log('catch error at space list', err);
            setIsLoading(false);
        })
    }

    const CustomOption = (props) => {
        console.log('props,', props)
        return (
            <components.Option {...props}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        props.data.bgColor &&
                        <div className='dropDownIcon' style={{
                            backgroundColor: props.data.bgColor,
                            marginRight: 5
                        }} />
                    }
                    <div>
                        <div>{props.data.label}</div>
                    </div>
                </div>
            </components.Option>
        );
    };

    useEffect(() => {
        getTableList();
    }, [])

    const handleTableClick = (item) => {
        console.log('item', item);
        // const data = item['data'];
        // const tableId = item['_id'];
        // const orderId = data['orderID'];
        setSelectedTableId(item['tableID']);
        setSelectedOrderId(item['orderID'])
        setIsSidebarShow(!isSidebarShow);
    }

    const closeSideBar = () => {
        getTableList();
        setIsSidebarShow(false);
    }

    return (
        <Container fluid className="main-content-container px-4 py-4">
            {/* <Row noGutters className="page-header py-4">
                <Col sm="12" md="8" lg="3">
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <Select
                                options={items}
                                onChange={handleChangeStatus}
                                components={{ Option: CustomOption }}
                                placeholder={strings.select_table_status}
                                styles={customStyles}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}

            <Offcanvas
                show={isSidebarShow}
                placement="end"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title>{strings.restaurant}</Offcanvas.Title>
                    <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            swal
                                .fire({
                                    title: '',
                                    text:
                                        '¿Desea cerrar esta pestaña?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'Cancelar'
                                })
                                .then(response => {
                                    if (response.isConfirmed) {
                                        getTableList();
                                        setIsSidebarShow(false);
                                    }
                                });
                        }}
                    ></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <RestaurantOrderSummary
                        id={selectedTableId}
                        orderID={selectedOrderId}
                        refreshTableList={getTableList}
                        closeSideBar={closeSideBar}
                    />
                </Offcanvas.Body>
            </Offcanvas>

            <Row>
                <Col>
                    <Card small className="mb-4 tableCard">
                        <CardBody className="p-3 b-3">
                            <PageTitle
                                sm="4"
                                title={strings.choose_table}
                                className="text-sm-left page-title"
                            />
                            <hr />


                            <Row noGutters className="py-4 parent" >
                                <Col sm="12" md="10" lg="10">
                                    {
                                        isLoading
                                            ? <h4>Loading...</h4>
                                            :
                                            <div>
                                                {
                                                    spaceData && spaceData.length > 1
                                                        ? <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                                            <Tabs
                                                                id="justify-tab-example"
                                                                className="mb-3"
                                                                fill
                                                                style={{
                                                                    overflowX: 'auto',
                                                                    overflowY: 'hidden',
                                                                    flexWrap: 'nowrap'
                                                                }}
                                                            >
                                                                {
                                                                    spaceData.map((item, index) => {
                                                                        const data = item['data'];
                                                                        const tableData = data['tableIDs'];
                                                                        return (
                                                                            <Tab eventKey={data['spaceName']} title={`${data['spaceName']}`}>
                                                                                <div className="MainDiv tableParentContainer">
                                                                                    <TableList
                                                                                        tableList={tableData}
                                                                                        onTableClick={(item) => handleTableClick(item)}
                                                                                    />
                                                                                </div>
                                                                            </Tab>
                                                                        )
                                                                    })
                                                                }

                                                            </Tabs>
                                                        </div>
                                                        :
                                                        <TableList
                                                            tableList={tableList}
                                                            onTableClick={(item) => handleTableClick(item)}
                                                        />
                                                }
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RestaurantTables