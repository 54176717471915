import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Col, Container, Form, FormInput, FormTextarea, InputGroup, InputGroupAddon, InputGroupText, ListGroup, ListGroupItem, Row } from 'shards-react'
import { strings } from '../../localization'
import PageTitle from '../../components/common/PageTitle'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import { API_URL } from '../../config'

const customStyles = {
    control: (provided) => ({
        ...provided,
        marginBottom: '15px',
        fontSize: '14px'
    }),
};

function ManageRestaurantSpace(props) {
    const { id } = props.match.params;

    // states
    const [spaceName, setSpaceName] = useState("");
    const [spaceDescription, setSpaceDescription] = useState("");
    const [tables, setTables] = useState([]);
    const [selectedTables, setSelectedTables] = useState([]);
    const [loader, setLoader] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    // This function is used to handle save button click event
    const handleSaveButtonClick = () => {
        if (!spaceName) {
            showToasMessage(strings.fill_fields, false);
        } else {
            const tablesId = [];
            if (selectedTables.length > 0) {
                selectedTables.map((item) => tablesId.push(item.value));
            }
            const bodyData = JSON.stringify({
                spaceName: spaceName,
                description: spaceDescription,
                tableIDs: tablesId
            })
            console.log('bodyData for create or edit space', bodyData);

            const spaceId = id ? `/${id}` : "";
            let statusCode = 0;
            fetch(`${API_URL}restaurant-space${spaceId}`, {
                method: spaceId ? "put" : "post",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: bodyData
            })
                .then(res => {
                    statusCode = res.status;
                    return res.json();
                })
                .then(res => {
                    console.log('space post api response', res);
                    const message = res['message'] ? res['message'] : "Something went wrong!";
                    setIsButtonDisabled(false);
                    if (statusCode === 200) {
                        showToasMessage(strings.successfully_created, true);
                    } else {
                        showToasMessage(message, false);
                    }
                })
                .catch(err => {
                    console.warn(err);
                    setIsButtonDisabled(false);
                    showToasMessage("Something went wrong!", false);
                });
        }
    }

    // This function is used to display toast message
    const showToasMessage = (message, isSuccess) => {
        if (isSuccess) {
            toast.success(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }

    // This function is used to handle table click event
    const handleTableSelection = (e) => {
        console.log('event', e);
        const selectedValue = e;
        if (selectedValue) {
            setSelectedTables([...selectedValue]);
        } else {
            setSelectedTables([]);
        }
    }

    // This function is used to call restaurant table get api
    const getTableList = () => {
        let statusCode = 0;
        fetch(`${API_URL}restaurant-table?restaurantSpaceID=null`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then((res) => {
                console.log('restaurant table get api response', res);
                const data = res['result'];
                console.log('data of table get api', data);
                if (statusCode === 200) {
                    if (data) {
                        const tableData = [];
                        data && data.length > 0 && data.map((item) => {
                            tableData.push({
                                label: item.data.tableName,
                                value: item._id
                            })
                        })
                        console.log('tableData', tableData);
                        setTables([...tableData]);
                    }
                    if (id) {
                        getSpaceDetail();
                    }
                } else {
                    showToasMessage("Something went wrong!", false);
                }
            }).catch((err) => {
                console.log('restaurant table get api catch block error', err);
                showToasMessage("Something went wrong!", false);
            })
    }

    // This function is used to call restaurant space detail get api
    const getSpaceDetail = () => {
        let statusCode = 0;
        fetch(`${API_URL}restaurant-space/${id}`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then((res) => {
                console.log('space detail api response', res);
                const data = res['data'];
                if (statusCode === 200) {
                    if (data) {
                        const tableList = data['tableIDs'];
                        const tableData = [];
                        setSpaceName(data['spaceName']);
                        setSpaceDescription(data['description']);
                        tableList && tableList.length > 0 && tableList.map((item) => {
                            tableData.push({
                                label: item.tableName,
                                value: item._id
                            })
                        })
                        console.log('tableData', tableData);
                        setSelectedTables([...tableData]);
                    }
                } else {
                    showToasMessage("Something went wrong!", false);
                }
            }).catch((err) => {
                console.log('restaurant space detail get api catch block error', err);
                showToasMessage("Something went wrong!", false);
            })
    }

    useEffect(() => {
        getTableList();
    }, [])

    return (
        <Container
            fluid
            className="main-content-container px-4"
            style={{ marginBottom: "200px" }}
        >
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title={id ? strings.edit_space : strings.create_space}
                    md="12"
                    className="ml-sm-auto mr-sm-auto"
                />
            </Row>
            <Row>
                <Col lg="6" md="10" sm="12">
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">{id ? strings.edit_space : strings.create_space}</h6>
                        </CardHeader>
                        <ListGroup flush>
                            <ListGroupItem className="p-3">
                                <Row>
                                    <Col>
                                        <Form id="TableForm">
                                            <label htmlFor="name">{strings.space_name}</label>
                                            <InputGroup seamless className="mb-3">
                                                <FormInput
                                                    type="text"
                                                    id="name"
                                                    value={spaceName}
                                                    placeholder={strings.space_name}
                                                    onChange={e =>
                                                        setSpaceName(e.target.value)
                                                    }
                                                />
                                            </InputGroup>
                                            <label htmlFor="description">{strings.space_description}</label>
                                            <InputGroup seamless className="mb-3">
                                                <FormTextarea
                                                    type="text"
                                                    id="description"
                                                    value={spaceDescription}
                                                    placeholder={strings.space_description}
                                                    onChange={e =>
                                                        setSpaceDescription(e.target.value)
                                                    }
                                                />
                                            </InputGroup>
                                            <Select
                                                options={tables}
                                                onChange={handleTableSelection}
                                                placeholder={strings.select_tables}
                                                value={selectedTables}
                                                isMulti
                                                styles={customStyles}
                                            />
                                            <ToastContainer />
                                            <Button
                                                theme="accent"
                                                onClick={handleSaveButtonClick}
                                                disabled={isButtonDisabled}
                                            >
                                                <i
                                                    className={loader}
                                                    style={{ fontSize: "15px" }}
                                                ></i>{" "}
                                                {strings.save}
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                <Col>
                    <Link to={"/restaurant-spaces"} className="float-left">
                        <Button
                            id="go_back"
                            theme="light"
                            className="mb-2 mr-1"
                            style={{
                                marginTop: "-84px",
                                position: "relative",
                                left: "-100px"
                            }}
                        >
                            {strings.go_back}
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default ManageRestaurantSpace