
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'shards-react'
import ReactDatatable from "@ashvin27/react-datatable";
import PageTitle from '../../components/common/PageTitle'
import { strings } from '../../localization'
import { Link } from 'react-router-dom';
import { API_URL, LIMIT } from '../../config';
import swal from "sweetalert2";
window.Swal = swal;


function RestaurantBarList() {

    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [columns, setColumns] = useState([]);
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(LIMIT);
    const [currentPage, setCurrentPage] = useState(1);
    const [prev, setPrev] = useState(true);
    const [next, setNext] = useState(false);

    const config = {
        // page_size: 10,
        show_length_menu: false,
        show_info: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false
        }
    };

    // This function is used to handle page increment
    const handlePageIncrement = () => {
        console.log('clicked on page increment')
        if (!next) {
            let skip = currentPage * limit;
            getBarData(skip);
            setCurrentPage(currentPage + 1)
            setPrev(false)
            setSkip(skip)
        }
    }

    // This function is used to handle page decrement
    const handlePageDecrement = () => {
        if (skip > 0) {
            let skipValue = skip - limit;
            if (skipValue >= 0) {
                getBarData(skipValue);
                if (skipValue === 0) {
                    setPrev(true);
                }
                setCurrentPage(currentPage - 1);
                setNext(false);
                setSkip(skipValue);
            } else {
                setPrev(true);
                setNext(false);
            }
        } else {
            setNext(false);
        }
    }

    // This function is used to display sweet alert and call restaurant single bar delete api
    const handleDeleteKitchen = (id) => {
        swal
            .fire({
                title: strings.are_you_sure,
                text: strings.delete_bar,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: strings.yes_cancel,
                confirmButtonColor: "#2E209D",
                cancelButtonColor: "#D61E11",
                confirmButtonText: strings.yes_delete,
                showLoaderOnConfirm: true,
                preConfirm: login => {
                    return fetch(`${API_URL}restaurant-bar/${id}`, {
                        method: "delete",
                        credentials: "include",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    })
                        .then(res => {
                            if (res.status == 200) {
                                setRecords([]);
                                setTotal(0);
                                setSkip(0);
                                setCurrentPage(1);
                                setPrev(true);
                                setNext(false);

                                getBarData();

                                swal.fire(
                                    strings.deleted,
                                    strings.success_bar_deleted,
                                    "success"
                                );
                            }
                            return res.json();
                        })
                        .catch(err => {
                            swal.showValidationMessage(`Request failed: ${err}`);
                        });
                }
            })
            .then(result => { });
    }

    // This function is used to call restaurant bar get api
    const getBarData = (skip = 0) => {
        setLoading(true);
        fetch(`${API_URL}restaurant-bar?limit=${limit}&skip=${skip}`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => res.json())
            .then(res => {
                console.log('response of restaurant bar', res);
                if (res.result.length > 0) {
                    let array = [];

                    res.result.map(item => {
                        let obj = item.data;
                        console.log("restaurant bar obj:", obj)
                        obj = Object.assign({ id: item._id }, obj);
                        array.push(obj);
                    });
                    console.log('res.result.length', res.result.length);
                    if (res.result.length !== limit) {
                        setNext(true)
                    }

                    setTotal(res.result.length + 1);
                    setRecords(array);
                    setLoading(false);
                } else {
                    setNext(true);
                    setLoading(false);
                    setRecords([]);
                }
            })
            .catch(err => {
                console.log('catch block error at getting bar list', err);
                setLoading(false);
            });
    }

    useEffect(() => {
        getBarData();
        setColumns([
            {
                key: "barName",
                text: strings.bar_name
            },
            {
                key: "userIDs",
                text: strings.waiters,
                cell: record => {
                    console.log('single record', record);
                    return (
                        <div>
                            {record.userIDs &&
                                record.userIDs.length > 0 &&
                                record.userIDs.map((item) => {
                                    return (
                                        <p className="badge badge-primary mx-2 my-2 text-capitalize" style={{ fontSize: '11px', backgroundColor: '#2e209b' }}>{item.fullName}</p>
                                    )
                                })
                            }
                        </div>
                    )
                }
            },
            {
                key: '',
                text: 'Acción',
                cell: record => {
                    console.log('record of tables', record)
                    return (
                        <div className='mt-2'>
                            <Link
                                to={{
                                    pathname: "/manage-restaurant-bar/" + record.id
                                }}
                            >
                                <Button theme="success" className="mb-2 mr-1">
                                    Editar
                                </Button>
                            </Link>
                            <Button
                                theme="danger"
                                className="mb-2 mx-2"
                                onClick={() => handleDeleteKitchen(record.id)}
                            >
                                {strings.delete}
                            </Button>
                        </div>
                    )
                }
            }
        ])
    }, [])

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    sm="4"
                    title={strings.restaurant_bars}
                    className="text-sm-left text-capitalize"
                />
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <Link to="manage-restaurant-bar">
                                <Button theme="primary" className="mb-2 float-right">
                                    <i class="material-icons">add</i> {strings.create_bar}
                                </Button>
                            </Link>
                            <div className="MainDiv">
                                <div className="table-responsive">
                                    <ReactDatatable
                                        config={config}
                                        records={records}
                                        columns={columns}
                                        loading={loading}
                                    />
                                    <nav className="d-flex justify-content-center align-items-center">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageDecrement}
                                                    style={
                                                        prev
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Anterior
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageIncrement}
                                                    style={
                                                        next
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Próxima
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RestaurantBarList