import React, { useEffect, useState } from 'react'
import ReactDatatable from "@ashvin27/react-datatable";
import { API_URL, getKOTStatusIcon, LIMIT } from '../../config';
import swal from "sweetalert2";
import { strings } from '../../localization';
import { Button } from 'shards-react';
import FoodInstructionDialog from '../../components/common/FoodInstructionDialog';
window.Swal = swal;

function KOTOrderList(props) {

    // const { loading, records, limit, config, handleStatus, isCurrentOrders } = props;

    const { loading, records, limit, config, handleStatus, isCurrentOrders, next, prev, handlePageIncrement, handlePageDecrement } = props;

    const [columns, setColumns] = useState([]);
    const [isNoteDialogVisible, setIsNoteDialogVisible] = useState(false);
    const [editFoodDialogItem, setEditFoodDialogItem] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const getBtnTitle = (status) => {
        let btnName = "";
        if (status === "in_queue") {
            btnName = strings.accept;
        } else if (status === "cooking") {
            btnName = strings.complete;
        }
        return btnName;
    }

    useEffect(() => {
        let displayCol = [
            {
                key: "itemName",
                text: strings.food_name,
                cell: record => {
                    return (
                        <div className='d-flex align-items-end'>
                            <p style={{ marginRight: '3px' }}>{record.itemName}</p>
                            {getKOTStatusIcon(record.status, 18, 'black')}
                        </div>
                    )
                }
            },
            {
                key: "tableName",
                text: strings.table_name,
            },
            {
                key: "quantity",
                text: strings.food_quantity,
                cell: record => {
                    console.log('single record', record);
                    return (
                        <p className="mx-2 my-2 text-capitalize" style={{ fontSize: '11px' }}>x{record.quantity}</p>
                    )
                }
            },
            {
                key: "",
                text: strings.note,
                cell: record => {
                    console.log('single record', record);
                    const note = record['note'];
                    return (
                        <div className='d-flex'>
                            <button
                                disabled={note && note.length > 0 ? false : true}
                                onClick={() => {
                                    setEditFoodDialogItem(record);
                                    setIsNoteDialogVisible(true);
                                }}
                                style={{ border: 0, backgroundColor: 'transparent' }}
                            >
                                {
                                    note && note.length > 0
                                        ?
                                        <i class="material-icons" style={{ fontSize: 22, color: '#2E1F9D', fontWeight: 400 }}>
                                            note
                                        </i>
                                        :
                                        <i class="material-icons-outlined" style={{ fontSize: 22, color: 'black', fontWeight: 400 }}>
                                            note
                                        </i>
                                }
                            </button>
                        </div>
                    )
                }
            },
        ]

        if (isCurrentOrders) {
            displayCol.push({
                key: '',
                text: 'Acción',
                cell: record => {
                    console.log('record of tables', record)
                    const kotID = record['kotID'];
                    const itemStatus = record['status'];
                    const itemID = record['id'];
                    return (
                        <div className='mt-2'>
                            <Button
                                theme="primary"
                                className="mb-2 mx-2"
                                style={{ width: '100px' }}
                                onClick={() => {
                                    if (itemStatus === "in_queue") {
                                        handleStatus(kotID, itemID, false);
                                    } else {
                                        handleStatus(kotID, itemID, true);
                                    }
                                }}
                            >
                                {getBtnTitle(itemStatus)}
                            </Button>
                        </div>
                    )
                }
            })
        }
        setColumns([...displayCol])
        setIsLoading(false);
    }, [])

    return (
        <>
            {
                isLoading
                    ? <p>loading..</p>
                    : <>
                        {isNoteDialogVisible &&
                            <FoodInstructionDialog
                                showModal={isNoteDialogVisible}
                                setShowModal={setIsNoteDialogVisible}
                                foodItem={editFoodDialogItem}
                                foodIndex={0}
                                onSaveButtonPressed={(itemData, index) => { }}
                                isSaveButtonLoading={false}
                                showSaveButton={false}
                            />
                        }
                        <div className="MainDiv">
                            <div className="table-responsive" style={{ overflowY: "auto", maxHeight: '50vh' }}>
                                <ReactDatatable
                                    config={config}
                                    records={records}
                                    columns={columns}
                                    loading={loading}
                                />
                                {
                                    !isCurrentOrders &&
                                    <nav className="d-flex justify-content-center align-items-center">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageDecrement}
                                                    style={
                                                        prev
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Anterior
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageIncrement}
                                                    style={
                                                        next
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Próxima
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default KOTOrderList