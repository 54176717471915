import React from 'react'
import '../views/restaurant/Restaurant.css'
import { Link } from 'react-router-dom'
import { strings } from '../localization'
import { EntypoBowl } from 'react-entypo-icons'
import { getKOTStatusIcon } from '../config'

function TableList({ tableList, onTableClick }) {
    return (
        tableList.length > 0
            ?
            tableList.length > 0 && tableList.map((item, index) => {
                let statusData = item['kot_status']
                return (
                    // <Link
                    //     to={{
                    //         pathname: `/restaurant/${item['tableID']}`,
                    //         state: {
                    //             orderId: item['orderID']
                    //         }
                    //     }}
                    // >
                    <div className='tableContainer'>
                        <div className='tableItemContainer'>
                            <div className='tableItem' style={{ borderColor: item['status']['bgColor'], backgroundColor: item['status']['bgColor'] }} />
                            <div className='tableItem' style={{ borderColor: item['status']['bgColor'], backgroundColor: item['status']['bgColor'] }} />
                        </div>
                        <div className='tableContentContainer' onClick={() => onTableClick(item)} style={{ backgroundColor: item['status']['bgColor'] }}>
                            <p className='tableInfoFontStyle' style={{ color: item['status']['textColor'] }}>{item['tableName']}</p>
                            <p className='tableInfoFontStyle' style={{ color: item['status']['textColor'] }}>{item['status']['label']}</p>
                            {
                                statusData && statusData.length > 0 &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    {
                                        statusData.map((statusItem, statusIndex) => {
                                            console.log('statusItem', statusItem)
                                            const status = statusItem['status'];
                                            const count = statusItem['count'];
                                            return (
                                                <>
                                                    {getKOTStatusIcon(status, 18, 'black')}
                                                    <p className='kotCountFontStyle'>{count}</p>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                        <div className='tableItemContainer'>
                            <div className='tableItem' style={{ borderColor: item['status']['bgColor'], backgroundColor: item['status']['bgColor'] }} />
                            <div className='tableItem' style={{ borderColor: item['status']['bgColor'], backgroundColor: item['status']['bgColor'] }} />
                        </div>
                    </div>
                    // </Link>
                )
            })
            : <h4 style={{ textAlign: 'center', width: '100%' }}>{strings.no_data_available}</h4>
    )
}

export default TableList