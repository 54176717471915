import React, { Component } from 'react';
import { strings } from "./../../localization";
import { Language, API_URL, LIMIT, roundWithTwoDecimals, DISPATCH_TYPE, BARCODE_RESPONSE_HEIGHT, BARCODE_RESPONSE_WIDTH, BARCODE_WIDTH, BARCODE_P_LEFT, BARCODE_P_WIDTH, getBarcodeValuesFromResponse, getBarcodeImageHeight, getItemMainPrice, getTotalPriceOfItem, formateNumber, RESTAURANT } from "./../../config";
import writtenNumber from 'written-number';
import { FormInput, FormTextarea, Button, Alert } from "shards-react";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import Invoice from '../Ventas/Invoice'
import moment from 'moment';
import Customer from '../Ventas/Customer';
let barcodeImage = ''
let printNumber = ''
let debitNoteReference = ''
var responseHeight = '';
var responseWidth = '';
var pLeft = BARCODE_P_LEFT;
var pWidth = BARCODE_P_WIDTH;
let barcodeWidth = BARCODE_WIDTH;
let barcodeHeight = 0

export class SampleInvoiceForModules extends Component {
    constructor(props) {
        super(props);
        strings.setLanguage(Language);
        this.componentRef = React.createRef(null);
        this.state = {
            totalPaid: '',
            return: '',
            date: '',
            time: '',
            taxAmount: '',
            dataArray: [],
            taxPR: 19,
            paymentMode: '',
            totalDiscount: '',
            type: '',
            displayDiscount: '',
            payable: '',
            // return: '',
            date: '',
            time: '',
            tax: false,
            rutNumber: '',
            name: '',
            addr1: '',
            addr2: '',
            country: '',
            city: '',
            state: '',
            contact: '',
            bDetails: '',
            view: '',
            loading: false,
            organisation_info: [],
            is_creating_record: false,
            showCustomerScreen: false,
            propinaAmount: 0,
            propinaChecked: null,
            propinaPercentage: 0,
            showPermissionForPropina: null,
            visible: false,
            companyLogo: '',
            organisation_id: '',
            message: '',
            references: [],
            transportData: [],
            transactionDocumentId: '',
            transactionDocumentType: '',
            editProductData: [],
            editProductFromTransaction: false,
            debitNoteReferencias: '',
            email: "",
            orderId: "",
            moduleName: "",
            showConvertButton: true,
            dataArrayForCustomer: [],
            documentTypeForCustomer: ""
        };
    }

    parseNumberToInt = (value) => {
        if (value) {
            value = value.replace('$', '');
            return parseInt(value.replace(/\./g, ''));
        }
    };


    updateData = async () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let time = today.toLocaleTimeString();
        today = dd + '/' + mm + '/' + yyyy;

        const param = this.props;
        console.log('*******', param);
        if (param !== undefined) {
            if (param.dataArray !== undefined) {
                let value = param.totalDiscount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

                this.setState(
                    {
                        dataArray: param.dataArray,
                        paymentMode: param.paymentMode,
                        totalDiscount: param.totalDiscount,
                        type: param.type,
                        displayDiscount: value,
                        totalPaid: param.totalPaid,
                        payable: param.total,
                        return: param.return,
                        date: today,
                        time: time,
                        tax: param.tax,
                        rutNumber: param.rutNumber,
                        name: param.name,
                        addr1: param.addr1,
                        addr2: param.addr2,
                        country: param.country,
                        city: param.city,
                        state: param.state,
                        contact: param.contact,
                        comment: param.comment,
                        bDetails: param.bDetails,
                        view: param.view,
                        withoutTax: param.withoutTax,
                        propinaAmount: param.propinaAmount,
                        propinaChecked: param.propinaChecked,
                        propinaPercentage: param.propinaPercentage,
                        showPermissionForPropina: param.showPermissionForPropina,
                        references: param.references,
                        transportData: param.transportData,
                        transactionDocumentId: param.transactionDocumentId,
                        transactionDocumentType: param.transactionDocumentType,
                        email: param.email,
                        moduleName: param.moduleName,
                        orderId: param.orderId,
                        showConvertButton: param.showConvertButton
                    },
                );
                // param.dataArray = undefined;
            }
        }
    };

    async componentDidMount() {
        this.updateData()
        writtenNumber.defaults.lang = 'es';
        let organisation_info = await localStorage.getItem('organisation_info');
        organisation_info = JSON.parse(organisation_info);

        this.setState({
            organisation_info: organisation_info.data,
            organisation_id: organisation_info._id,
        },
            () => this.getOrganisationData()
        );

        const params = this.props;
        console.log('params in sample invoices: ', params);
        if (params.editProductData && params.editProductFromTransaction && params.editProductFromTransaction) {
            this.setState({
                editProductData: params.editProductData,
                editProductFromTransaction: params.editProductFromTransaction
            })
        }
    }

    getOrganisationData() {
        console.log('oragnize data id', this.state.organisation_id);

        if (this.state.organisation_id) {
            fetch(`${API_URL}organisations/${this.state.organisation_id}`, {
                method: "GET",
                credentials: "include"
            })
                .then(res => res.json())
                .then(({ data }) => {
                    console.log('organization api response is : ', data);
                    this.setState({
                        companyLogo: data.companyLogo
                    });
                })
        } else {
            console.log('id is not found in organization api', this.state.organisation_info.id);
        }
    }

    // This function is used to generate data rows for organization info
    organizationInfoDataRowsGenerator = () => {
        let printRowData = [];

        let name = this.state.organisation_info['name'];
        if (this.state.organisation_info.hasOwnProperty('name')) printRowData.push(name);

        let address = this.state.organisation_info['address']
        if (this.state.organisation_info.hasOwnProperty('address')) printRowData.push(address);

        let city = this.state.organisation_info.hasOwnProperty('city')
            ? this.state.organisation_info['city']
            : '';

        let state = this.state.organisation_info.hasOwnProperty('state')
            ? this.state.organisation_info['state']
            : '';

        let city_state = city + ', ' + state;
        printRowData.push(city_state);

        let giro = 'GIRO : ' + this.state.organisation_info['giro'];
        if (this.state.organisation_info.hasOwnProperty('giro')) printRowData.push(giro);

        return printRowData;
    }

    // This function is used to generate data rows for customer info block 
    customerInfoSequantialDataRowsGenerator = () => {
        let printRowData = [];
        if (this.state.rutNumber && this.state.rutNumber !== "") {
            let rutRow = this.getDataRowObj("RUT", this.state.rutNumber);
            printRowData.push(rutRow);
        }

        if (this.state.name && this.state.name !== '') {
            let nombreRow = this.getDataRowObj("NOMBRE", this.state.name.toUpperCase());
            printRowData.push(nombreRow);
        }

        if (this.state.addr1 && this.state.addr1 !== '') {
            let directionRow = this.getDataRowObj("DIRECCION", this.state.addr1.toUpperCase());
            printRowData.push(directionRow);
        }

        if (this.state.addr2 && this.state.addr2 !== '') {
            let comunaRow = this.getDataRowObj("COMUNA", this.state.addr2.toUpperCase());
            printRowData.push(comunaRow);
        }

        if (this.state.city && this.state.city !== '') {
            let ciudadRow = this.getDataRowObj("CIUDAD", this.state.city.toUpperCase());
            printRowData.push(ciudadRow);
        }

        if (this.state.state && this.state.state) {
            let regionValue = `${this.state.state.toUpperCase()} ${this.state.country ? this.state.country.toUpperCase() : ""}`;
            let regionRow = this.getDataRowObj("REGION", regionValue);
            printRowData.push(regionRow);
        }

        if (this.state.bDetails && this.state.bDetails !== '') {
            let giroRow = this.getDataRowObj("GIRO", this.state.bDetails.toUpperCase());
            printRowData.push(giroRow);
        }

        if (this.state.contact && this.state.contact !== '') {
            let contactRow = this.getDataRowObj("NOMBRE DE CONTACTO", this.state.contact.toUpperCase());
            printRowData.push(contactRow);
        }

        return printRowData;
    }

    // This is utility function is used to generate label & value object
    getDataRowObj = (label, value) => {
        return {
            "label": label,
            "value": value,
            "separator": "$"
        }
    }

    // This function is used to generate data rows for items data array
    itemsDataArrayDataRowsGenerator = () => {
        let formattedDataArray = [];

        formattedDataArray = this.state.dataArray.map(({ description, itemType, mainPrice, quantity, total }) => ({
            description,
            itemType,
            mainPrice,
            quantity,
            total
        }));
        return formattedDataArray;
    }

    // This function is used to generate sequantial row data for items evaluate block (items data array after block)
    itemsEvaluateBlockSequantialDataRows = () => {
        let printRowData = []

        var subtotal = 0;
        var totalAll = 0;
        let neto = 0;
        let taxAmount = 0;

        let FAWT = 0;
        let iva = 0;
        let mainPrice = 0;
        let mainPriceWithTax = 0;
        let quantity = 0;

        this.state.dataArray.map((data) => {
            subtotal += data.total;
            subtotal = roundWithTwoDecimals(subtotal);
            totalAll += data.total;

            mainPriceWithTax = roundWithTwoDecimals(parseInt(data.mainPrice));
            mainPrice = mainPriceWithTax / 1.19;
            mainPrice = mainPrice ? roundWithTwoDecimals(mainPrice) : 0;
            quantity = parseFloat(data.quantity);
            FAWT = mainPrice * quantity;
            iva = (mainPriceWithTax * quantity) - FAWT;
            iva = iva ? Math.round(iva) : 0;

            neto = neto + FAWT;
            neto = Math.round(neto)
            taxAmount = taxAmount + iva;
        })

        if (this.state.moduleName && this.state.moduleName === RESTAURANT) {
            printRowData = this.restaurantModuleItemsEvaluateBlockRowData(neto, taxAmount, totalAll);
        }
        return printRowData;
    }

    // This function is used to generate sequantial data rows for items evaluate block (items data array after block)
    // DO NOT CHANGE THE SEQUENCE OF DATA ROWS UNLESS YOU NEED IT
    restaurantModuleItemsEvaluateBlockRowData = (neto, taxAmount, totalAll) => {
        let printRowData = []
        // let subtotalRow = this.getDataRowObj("SUBTOTAL", formateNumber(subtotal))
        // printRowData.push(subtotalRow)

        const netoTitle = this.state.withoutTax === true ? 'MONTO EXENTO' : 'NETO';
        const netoRow = this.getDataRowObj(netoTitle, formateNumber(neto, true));
        printRowData.push(netoRow);

        const totalIvaRow = this.getDataRowObj('TOTAL IVA (19 %) ', formateNumber(taxAmount, true));
        printRowData.push(totalIvaRow);

        let totalRow = this.getDataRowObj("TOTAL", formateNumber(Math.round(totalAll), true))
        printRowData.push(totalRow)

        if (
            this.state.showPermissionForPropina && this.state.propinaChecked
        ) {
            let propinaRow = this.getDataRowObj(`PROPINA SUGERIDA ${this.state.propinaPercentage}%`, formateNumber(this.state.propinaAmount, true))
            printRowData.push(propinaRow)

            let totalPlusPropinaAmountRow = this.getDataRowObj(
                "TOTAL + PROPINA", formateNumber(Math.round(totalAll) + this.state.propinaAmount, true)
            )
            printRowData.push(totalPlusPropinaAmountRow)
        }
        console.log('totalAll', totalAll);
        console.log('this.state.totalPaid', this.state.totalPaid);
        let paymentModeRow = this.getDataRowObj(this.state.paymentMode, this.state.totalPaid.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
        printRowData.push(paymentModeRow)

        let returnAmountRow = this.getDataRowObj("VUELTO", this.state.return)
        printRowData.push(returnAmountRow)

        console.log('restaurant module row data', printRowData)
        return printRowData;
    }

    // This function is used to generate data for footer block
    footerBlockDataRows = () => {
        let printRowData = [];
        let itemsTotal = 0;
        this.state.dataArray.map((item) => {
            itemsTotal += item.total;
        })
        console.log('itemsTotal', itemsTotal);

        if (this.state.moduleName && this.state.moduleName === RESTAURANT) {
            let sonRow = this.getDataRowObj("son", writtenNumber(itemsTotal));
            printRowData.push(sonRow);
            let commentRow = this.getDataRowObj(strings.comment, this.state.comment)
            if (this.state.comment && this.state.comment !== "") printRowData.push(commentRow);
        }
        return printRowData;
    }

    // This funciton is used to calculate data array based on document type
    handleDocumentClick = (type) => {
        // const dataArrayFromParams = [...this.state.dataArray];
        const dataArrayFromParams = JSON.parse(JSON.stringify(this.state.dataArray));
        let FAWT = 0;
        let iva = 0;
        let mainPrice = 0;
        let mainPriceWithTax = 0;
        let quantity = 0;

        dataArrayFromParams.map((item, index) => {
            quantity = parseFloat(item.quantity);
            if (type === 1) {
                mainPrice = roundWithTwoDecimals(parseInt(item.mainPrice));
                let AWT = 0;
                let totalPrice = mainPrice * quantity;
                AWT = !this.state.withoutTax ? totalPrice / 1.19 : totalPrice;
                //   TODO: IF NEED TO ADD DISCOUNT THEN THIS CODE NEED TO UNCOMMENT
                //   let DWT = !this.state.withoutTax ? singleItemDiscount / 1.19 : singleItemDiscount;
                let DWT = 0;
                FAWT = AWT - DWT;
                iva = !this.state.withoutTax ? FAWT * 0.19 : 0;
            } else if (type === 2) {
                mainPriceWithTax = roundWithTwoDecimals(parseInt(item.mainPrice));
                mainPrice = mainPriceWithTax / 1.19;
                mainPrice = mainPrice ? roundWithTwoDecimals(mainPrice) : 0;
                FAWT = mainPrice * quantity;
                iva = (mainPriceWithTax * quantity) - FAWT;
                iva = iva ? Math.round(iva) : 0;
            }

            dataArrayFromParams[index]['mainPrice'] = mainPrice;
            dataArrayFromParams[index]['mainPriceWithTax'] = mainPriceWithTax;
            dataArrayFromParams[index]['quantity'] = quantity;
            dataArrayFromParams[index]['FAWT'] = FAWT ? roundWithTwoDecimals(FAWT) : 0;
            dataArrayFromParams[index]['iva'] = iva;
        })
        console.log('handleDocumentClick function type', type)
        console.log('handleDocumentClick function dataArrayFromParams', dataArrayFromParams)
        this.setState({
            dataArrayForCustomer: dataArrayFromParams,
            documentTypeForCustomer: type,
            showCustomerScreen: true
        })
    }

    render() {
        console.log("Sample invoice state.....", this.props)
        console.log('company logo is', this.state.companyLogo)
        console.log('debitNoteReference is', debitNoteReference)
        console.log('dataArray in sample invoice for modules', this.state.dataArray)

        var subtotal = 0;
        var totalAfecto = '';
        var taxAmount = 0;
        var totalAll = 0;
        var neto = 0;
        let discountAmount = 0;


        return (
            <>
                {!this.state.showCustomerScreen ? <>
                    <div

                    >
                        <div
                            ref={this.componentRef}
                            className="sample-invoices"
                            style={{
                                backgroundColor: '#fff',
                                width: '100%',
                                borderColor: '#999999',
                                borderWidth: 1,
                                padding: 20
                            }}>

                            <div>
                                <p style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    opacity: 0.7,
                                }} >
                                    -----------------------------------------------
                                </p>
                                <p style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    opacity: 0.7,
                                }}>
                                    R.U.T. :{' '}
                                    {this.state.organisation_info.hasOwnProperty('rutNumber')
                                        ? this.state.organisation_info['rutNumber']
                                        : null}
                                </p>
                                {this.state.date && this.state.time ? (
                                    <p style={{
                                        textAlign: 'center',
                                        fontSize: 15,
                                        opacity: 0.7,
                                    }}>
                                        Fecha : {this.state.date} {this.state.time}
                                    </p>
                                ) : null}
                                <p style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    opacity: 0.7,
                                }} >
                                    -----------------------------------------------
                                </p>
                            </div>

                            <div
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '10px'
                                }}
                            >
                                {
                                    this.state.companyLogo !== undefined
                                    && this.state.companyLogo !== ''
                                    && <img
                                        style={{
                                            width: "auto",
                                            height: "100px",
                                        }}
                                        src={this.state.companyLogo}
                                        alt="EBOL"
                                    />
                                }
                            </div>

                            {
                                this.organizationInfoDataRowsGenerator().map((singleItemRowData, index) => {
                                    let isLastIndex = this.organizationInfoDataRowsGenerator().length - 1 === index;
                                    return (
                                        <p
                                            style={{ textAlign: 'left', marginTop: isLastIndex ? 10 : 0, fontSize: 15, opacity: 0.7, }}>
                                            {singleItemRowData}
                                        </p>
                                    )
                                })
                            }

                            <p
                                style={{ textAlign: 'center', fontSize: 15, opacity: 0.7, }}
                            >
                                -----------------------------------------------
                            </p>

                            {
                                this.customerInfoSequantialDataRowsGenerator().map((singleItemRowData) => {
                                    return (
                                        <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                                            {singleItemRowData.label} : {singleItemRowData.value}
                                        </p>
                                    )
                                })
                            }



                            {(this.state.rutNumber !== undefined &&
                                this.state.rutNumber !== '') ||
                                (this.state.addr1 !== undefined && this.state.addr1 !== '') ||
                                (this.state.addr2 !== undefined && this.state.addr2 !== '') ||
                                (this.state.city !== undefined && this.state.city !== '') ||
                                (this.state.country !== undefined && this.state.country !== '') ||
                                (this.state.state !== undefined && this.state.state !== '') ||
                                (this.state.phone !== undefined && this.state.phone !== '') ||
                                (this.state.bDetails !== undefined && this.state.bDetails !== '') ||
                                (this.state.name !== undefined && this.state.name !== '') ? (
                                <p
                                    style={{ textAlign: 'center', fontSize: 15, opacity: 0.7, }}
                                >
                                    -----------------------------------------------
                                </p>
                            ) : null}

                            {
                                this.itemsDataArrayDataRowsGenerator().map((singleItemRowData) => {
                                    let mainPriceWithoutTax = singleItemRowData.mainPrice / 1.19;
                                    mainPriceWithoutTax = roundWithTwoDecimals(mainPriceWithoutTax);
                                    let quantity = singleItemRowData.quantity.toString().replace(/\,/g, '.');
                                    let totalPriceWithoutTax = mainPriceWithoutTax * quantity;
                                    totalPriceWithoutTax = Math.round(totalPriceWithoutTax);
                                    return (
                                        <>
                                            <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                                                {singleItemRowData.description}
                                            </p>
                                            <div className="d-flex">
                                                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                                                    {singleItemRowData.quantity} {singleItemRowData.itemType} X{' '}
                                                    {formateNumber(mainPriceWithoutTax)}
                                                </p>
                                                <p
                                                    style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                                                    {formateNumber(totalPriceWithoutTax)}
                                                </p>
                                            </div>
                                        </>
                                    )
                                })
                            }

                            {
                                this.itemsEvaluateBlockSequantialDataRows().map((singleRowData) => {
                                    console.log('singleRowData', singleRowData)
                                    return (
                                        <div className="d-flex">
                                            <p
                                                style={{ width: '60%', textAlign: 'right', fontSize: 15, opacity: 0.7, textTransform: 'uppercase' }}>
                                                {singleRowData.label}
                                            </p>
                                            <p style={{
                                                textAlign: 'center',
                                                fontSize: 15,
                                                opacity: 0.7,
                                                marginLeft: 18
                                            }}> {'  '}$</p>
                                            <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                                                {singleRowData.value}
                                            </p>
                                        </div>

                                    );
                                })
                            }

                            <div className='mt-5'>
                                {
                                    this.footerBlockDataRows().map((singleItemRowData) => {
                                        return (

                                            <p
                                                style={{ textAlign: 'left', marginTop: 5, fontSize: 15, opacity: 0.7, }}>
                                                {singleItemRowData.label} : {singleItemRowData.value}
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-center">
                        <ReactToPrint content={() => this.componentRef.current}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button pill size='lg' className='mt-4' style={{
                                        width: '40%', alignSelf: 'center'
                                    }}
                                        onClick={handlePrint}
                                    >{strings.print}</Button>

                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                    </div>
                    <div className={this.state.showConvertButton ? "mt-4 mb-2 d-flex justify-content-between" : 'd-none'}>
                        <Button pill size='lg' className='mt-4 mr-2' style={{
                            width: '48%', alignSelf: 'center'
                        }}
                            onClick={() => {
                                this.handleDocumentClick(1)
                            }}
                        >{strings.convert_to_bill}
                        </Button>
                        <Button pill size='lg' className='mt-4' style={{
                            width: '48%', alignSelf: 'center'
                        }}
                            onClick={
                                () => {
                                    this.handleDocumentClick(2)
                                }
                            }
                        >
                            {this.state.loading
                                ? <div
                                    class="spinner-border text-light"
                                    role="status"
                                    style={{
                                        height: 20, width: 20
                                    }}
                                ></div>
                                : strings.convert_to_invoice
                            }
                        </Button>
                    </div>
                    <Alert open={this.state.visible} theme='danger' style={{
                        position: 'fixed',
                        bottom: 10,
                        // width: '80%',
                    }}>
                        {this.state.message}
                    </Alert>

                </>
                    :
                    <Customer
                        onBack={() => {
                            this.setState({
                                showCustomerScreen: false
                            })
                        }}
                        dataArray={this.state.dataArrayForCustomer}
                        calculatorReference={this.state.calculatorReference}
                        totalPaid={this.state.totalPaid}
                        mainPrice={this.state.mainPrice}
                        tax={undefined}
                        total={this.state.payable}
                        return={this.state.return}
                        paymentMode={this.state.paymentMode}
                        totalDiscount={this.state.totalDiscount}
                        type={this.state.documentTypeForCustomer}
                        withoutTax={this.state.withoutTax}
                        propinaAmount={
                            this.state.propinaAmount
                        }
                        propinaChecked={
                            this.state.propinaChecked
                        }
                        propinaPercentage={this.state.propinaPercentage}
                        showPermissionForPropina={this.state.showPermissionForPropina}
                        closeAll={() => {
                            this.setState({
                                showCustomerScreen: false,
                                isNextButtonClick: false,
                                description: ''
                            });
                            this.clearAll();
                        }}
                        onBackWhenDirectPrint={() => {
                            this.setState({ showCustomerScreen: false, finalPrice: '$0' });
                        }}
                        bypassCustomerScreen={false}
                        transactionDocumentId={this.state.transactionDocumentId}
                        transactionDocumentType={this.state.transactionDocumentType}
                        editProductData={this.state.editProductData}
                        editProductFromTransaction={this.state.editProductFromTransaction}
                        orderId={this.state.orderId}
                        moduleName={this.state.moduleName}
                        closeSideBar={this.props.closeSideBar}
                    />
                }
            </>
        )
    }
}

export default SampleInvoiceForModules;
