import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Col, Container, Form, FormInput, InputGroup, InputGroupAddon, InputGroupText, ListGroup, ListGroupItem, Row } from 'shards-react'
import { strings } from '../../localization'
import PageTitle from '../../components/common/PageTitle'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import { API_URL } from '../../config'

const customStyles = {
    control: (provided) => ({
        ...provided,
        marginBottom: '15px',
        fontSize: '14px'
    }),
};

function ManageRestaurantKitchen(props) {
    const { id } = props.match.params;
    // states
    const [kitchenName, setKitchenName] = useState("");
    const [chefs, setChefs] = useState([]);
    const [selectedChefs, setSelectedChefs] = useState([]);
    const [loader, setLoader] = useState("",)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    // This function is used to call restaurant kitchen create or update api
    const handleSaveButtonClick = () => {
        setIsButtonDisabled(true);
        if (!kitchenName) {
            showToasMessage(strings.fill_fields, false);
            setIsButtonDisabled(false);
        } else {
            let chefIds = [];
            if (selectedChefs.length > 0) {
                selectedChefs.map((item) => chefIds.push(item.value));
            }
            // console.log('chefids', chefIds)
            const bodyData = JSON.stringify({
                kitchenName,
                userIDs: chefIds
            })
            // console.log('bodyData for create or edit kitchen', bodyData);
            const kitchenId = id ? `/${id}` : "";
            let statusCode = 0;
            fetch(`${API_URL}restaurant-kitchen${kitchenId}`, {
                method: kitchenId ? "put" : "post",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: bodyData
            })
                .then(res => {
                    statusCode = res.status;
                    return res.json();
                })
                .then(res => {
                    console.log('kitchen post api response', res);
                    const message = res['message'] ? res['message'] : "Something went wrong!";
                    setIsButtonDisabled(false);
                    if (statusCode === 200) {
                        showToasMessage(strings.successfully_created, true);
                    } else {
                        showToasMessage(message, false);
                    }
                })
                .catch(err => {
                    console.warn(err);
                    setIsButtonDisabled(false);
                    showToasMessage("Something went wrong!", false);
                });
        }
    }

    // This function is used to display toast
    const showToasMessage = (message, isSuccess) => {
        if (isSuccess) {
            toast.success(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }

    // This function is used to handle chef selection dropdown onChange method
    const handleChefSelection = (e) => {
        console.log('event', e);
        const selectedValue = e;
        if (selectedValue) {
            setSelectedChefs([...selectedValue]);
        } else {
            setSelectedChefs([]);
        }
    }

    // This function is used to call restaurant chef role users get api
    const getChefList = () => {
        let statusCode = 0;
        fetch(`${API_URL}get-users?restaurantModule=true&userRestaurantRole=restaurant_chef`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then((res) => {
                let userData = [];
                console.log('response of user list', res)
                if (statusCode === 200) {
                    const result = res['result'];
                    if (result && result.length > 0) {
                        result.map((item, index) => {
                            const userInfo = item['data'];
                            const id = item['_id'];
                            const name = userInfo['fullName'];
                            userData.push({
                                label: name,
                                value: id
                            })
                        })
                        // console.log('user data before set', userData);
                        setChefs([...userData])
                    }
                    if (id) {
                        getKitchenDetail();
                    }
                } else {
                    showToasMessage("Something went wrong!", false);
                }
            }).catch((err) => {
                console.log('catch block error at get user list in manage restaurant kitchen', err);
                showToasMessage("Something went wrong!", false);
            })
    }

    // This function is used to call restaurant kitchen detail api
    const getKitchenDetail = () => {
        let statusCode = 0;
        fetch(`${API_URL}restaurant-kitchen/${id}`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then((res) => {
                console.log('kitchen detail api response', res);
                const data = res['data'];
                if (statusCode === 200) {
                    if (data) {
                        const chefsList = data['userIDs'];
                        const chefData = [];
                        setKitchenName(data['kitchenName']);
                        chefsList && chefsList.length > 0 && chefsList.map((item) => {
                            chefData.push({
                                label: item.fullName,
                                value: item._id
                            })
                        })
                        console.log('chefData', chefData);
                        setSelectedChefs([...chefData]);
                    }
                } else {
                    showToasMessage("Something went wrong!", false);
                }
            }).catch((err) => {
                console.log('restaurant kitchen detail get api catch block error', err);
                showToasMessage("Something went wrong!", false);
            })
    }

    useEffect(() => {
        getChefList();
    }, [])

    return (
        <Container
            fluid
            className="main-content-container px-4"
            style={{ marginBottom: "200px" }}
        >
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title={id ? strings.edit_kitchen : strings.create_kitchen}
                    md="12"
                    className="ml-sm-auto mr-sm-auto"
                />
            </Row>
            <Row>
                <Col lg="6" md="10" sm="12">
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">{id ? strings.edit_kitchen : strings.create_kitchen}</h6>
                        </CardHeader>
                        <ListGroup flush>
                            <ListGroupItem className="p-3">
                                <Row>
                                    <Col>
                                        <Form id="TableForm">
                                            <label htmlFor="code">{strings.kitchen_name}</label>
                                            <InputGroup seamless className="mb-3">
                                                <FormInput
                                                    type="text"
                                                    id="code"
                                                    value={kitchenName}
                                                    placeholder={strings.kitchen_name}
                                                    onChange={e =>
                                                        setKitchenName(e.target.value)
                                                    }
                                                />
                                            </InputGroup>
                                            <Select
                                                options={chefs}
                                                onChange={handleChefSelection}
                                                placeholder={strings.select_chefs}
                                                value={selectedChefs}
                                                isMulti
                                                styles={customStyles}
                                            />
                                            <ToastContainer />
                                            <Button
                                                theme="accent"
                                                onClick={handleSaveButtonClick}
                                                disabled={isButtonDisabled}
                                            >
                                                <i
                                                    className={loader}
                                                    style={{ fontSize: "15px" }}
                                                ></i>{" "}
                                                {strings.save}
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                <Col>
                    <Link to={"/restaurant-kitchens"} className="float-left">
                        <Button
                            id="go_back"
                            theme="light"
                            className="mb-2 mr-1"
                            style={{
                                marginTop: "-84px",
                                position: "relative",
                                left: "-100px"
                            }}
                        >
                            {strings.go_back}
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default ManageRestaurantKitchen