import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
import CustomLayout from "./layouts/Custom";
import Charts from "./views/charts";

// Route Views
import Dashboard from "./views/Dashboard";
import Errors from "./views/Errors";
import Login from "./views/Login";
import EditProfile from "./views/EditProfile";
import UserRequest from "./views/UserRequest";
import ManageUser from "./views/users/manage";
import ManageUserPermissions from "./views/users/permissions";
import ManageCustomer from "./views/customers/manage";
import ViewTransaction from "./views/Transactions/view";
// import Settings from "./views/Settings";
import Users from "./views/users";
import Customers from "./views/customers/index";
import Items from "./views/items";
import Transactions from "./views/Transactions/index";
import ManageItem from "./views/items/manage";
import Folios from "./views/folios";
import ManageFolios from "./views/folios/manage";
import FolioConsumptions from "./views/folio_consumptions";
import ManageFolioConsumption from "./views/folio_consumptions/manage";
import { Session } from "bc-react-session";
import Invoice from "./views/invoice";
import PurchaseDocuments from "./views/PurchaseDocuments"
import Ventas from '../src/views/Ventas'
import ResetPassword from "./components/Login/ResetPassword";
import ForgotPassword from "./components/Login/ForgotPassword";
import RestaurantTableList from "./views/restaurant_table";
import ManageTable from "./views/restaurant_table/manage";
import RestaurantTables from "./views/restaurant/RestaurantTables";
import RestaurantKOTOrders from "./views/restaurant_kot_orders/RestaurantKOTOrders";
import RestaurantKitchens from "./views/restaurant_kitchen";
import ManageRestaurantKitchen from "./views/restaurant_kitchen/manage";
import RestaurantBars from "./views/restaurant_bar";
import ManageRestaurantBar from "./views/restaurant_bar/manage";
import RestaurantSpaces from "./views/restaurant_space";
import ManageRestaurantSpace from "./views/restaurant_space/manage";
import RestaurantReport from "./views/restaurant_report";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

let session = Session.get();
let loggedIn = session.isValid;


//loggedIn=true;
const logout = () => {
  Session.destroy();
  window.location = "/";
};

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/invoice",
    layout: CustomLayout,
    component: () => <Invoice />
  },
  {
    path: "/transaction-verify",
    layout: CustomLayout,
    component: () => <Login />
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Dashboard /> : <Redirect to="/login" />)
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: (
      <Errors
        number="500"
        message="There was a problem on our end. Please try again later"
        text="Something went wrong"
      />
    )
  },
  {
    path: "/login",
    layout: CustomLayout,
    component: () => (loggedIn ? <Redirect to="/dashboard" /> : <Login />)
  },
  {
    path: "/reset-password",
    layout: CustomLayout,
    component: props => <ResetPassword {...props} />
  },
  {
    path: "/forget-password",
    layout: CustomLayout,
    component: () => (<ForgotPassword />)
  },
  {
    path: "/edit-user-profile",
    layout: DefaultLayout,
    component: () => (loggedIn ? <EditProfile /> : <Redirect to="/login" />)
  },
  {
    path: "/user-request",
    layout: DefaultLayout,
    component: () => (loggedIn ? <UserRequest /> : <Redirect to="/login" />)
  },
  {
    path: "/manage-user/create",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageUser {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-user/:type/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageUser {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/permissions/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageUserPermissions {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-customer/create",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageCustomer {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-customer/:type/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageCustomer {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/view-transaction/:type/:id/:barcode?",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ViewTransaction {...props} /> : <Redirect to="/login" />
  },
  // {
  //   path: "/settings",
  //   layout: DefaultLayout,
  //   component: () => (loggedIn ? <Settings /> : <Redirect to="/login" />)
  // },
  {
    path: "/folios",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Folios /> : <Redirect to="/login" />)
  },
  {
    path: "/PurchaseDocuments",
    layout: DefaultLayout,
    component: () => (loggedIn ? <PurchaseDocuments /> : <Redirect to="/login" />)
  },
  {
    path: "/manage-folio/create",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageFolios {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-folio/:type/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageFolios {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/folio-consumptions",
    layout: DefaultLayout,
    component: () =>
      loggedIn ? <FolioConsumptions /> : <Redirect to="/login" />
  },
  {
    path: "/manage-folio-consumption/create",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? (
        <ManageFolioConsumption {...props} />
      ) : (
        <Redirect to="/login" />
      )
  },
  {
    path: "/manage-folio-consumption/:type/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? (
        <ManageFolioConsumption {...props} />
      ) : (
        <Redirect to="/login" />
      )
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <Users {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/customers",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <Customers {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/items",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Items /> : <Redirect to="/login" />)
  },
  {
    path: "/transactions",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Transactions /> : <Redirect to="/login" />)
  },
  {
    path: "/Ventas",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Ventas /> : <Redirect to="/login" />)
  },
  {
    path: "/manage-item/:id?",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageItem {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/charts",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Charts /> : <Redirect to="/login" />)
  },
  {
    path: '/tables',
    layout: DefaultLayout,
    component: () => (loggedIn ? <RestaurantTableList /> : <Redirect to="/login" />)
  },
  {
    path: "/manage-table/:id?",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageTable {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/restaurant",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <RestaurantTables {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/restaurant-orders",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <RestaurantKOTOrders {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/restaurant-report",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <RestaurantReport {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/restaurant-kitchens",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <RestaurantKitchens {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-restaurant-kitchen/:id?",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <ManageRestaurantKitchen {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/restaurant-bars",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <RestaurantBars {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-restaurant-bar/:id?",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <ManageRestaurantBar {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/restaurant-spaces",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <RestaurantSpaces {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-restaurant-space/:id?",
    layout: DefaultLayout,
    exact: true,
    component: props =>
      loggedIn ? <ManageRestaurantSpace {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/logout",
    layout: CustomLayout,
    component: () => logout()
  },
  {
    path: "/404",
    layout: DefaultLayout,
    component: () => (
      <Errors
        exact={true}
        number="404"
        message="Not Fount"
        text="Page you are looking was not found !!"
      />
    )
  }
];
