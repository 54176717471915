import React, { useEffect, useState } from 'react'
import { API_URL, LIMIT } from '../../config';
import swal from "sweetalert2";
import { strings } from '../../localization';
import KOTOrderList from '../../components/Restaurant/KOTOrderList';
window.Swal = swal;

function CurrentKOTOrders() {

    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [limit, setLimit] = useState(LIMIT);

    const config = {
        page_size: 100000,
        show_length_menu: false,
        show_info: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false
        }
    };

    const getCurrentKOTOrderList = (skip = 0) => {
        setLoading(true);
        let statusCode = 0;
        const apiUrl = `${API_URL}restaurant-kot?status=in_queue,cooking`
        console.log('api url of get queue kot orders', apiUrl);
        fetch(apiUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            console.log('status code of get kot orders', statusCode);
            return res.json();
        }).then((res) => {
            console.log('response of get kot queue orders', res);
            if (statusCode === 200) {
                setLoading(false);
                const data = res['result'];
                console.log('data of kot queue orders', data)
                if (data && data.length > 0) {
                    let orders = [];
                    data.map((item, index) => {
                        const orderData = item['data'];
                        let dataArray = orderData['dataArray'] ? orderData['dataArray'] : [];

                        const kotID = item['_id'];
                        const kotDisplayID = orderData['kotID'];
                        const tableDetail = orderData['tableDetail'];
                        const orderDetail = orderData['orderDetail'];
                        const orderDisplayID = orderDetail['orderID'];
                        const tableName = tableDetail ? tableDetail['tableName'] : ""

                        dataArray.map((foodItem, foodIndex) => {
                            console.log('foodItem', foodItem);
                            foodItem['kotID'] = kotID;
                            foodItem['tableName'] = tableName;
                            foodItem['kotDisplayID'] = kotDisplayID;
                            foodItem['orderDisplayID'] = orderDisplayID;
                            orders.push(foodItem);
                        })
                    })
                    console.log('orders', orders)
                    setRecords([...orders]);
                } else {
                    setRecords([]);
                }
            } else {
                const msg = res['message'] ? res['message'] : "Something went wrong!"
                setLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at get kot queue orders', err);
            setLoading(false);
        })
    }

    useEffect(() => {
        getCurrentKOTOrderList();
    }, [])

    const handleStatus = (kotID, itemID, isFromCooking) => {
        swal
            .fire({
                title: isFromCooking ? strings.complete_item_title : strings.accept_item_title,
                text: isFromCooking ? strings.complete_item : strings.accept_item,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#2E209D",
                cancelButtonColor: "#D61E11",
                confirmButtonText: strings.yes,
                cancelButtonText: strings.no,
                showLoaderOnConfirm: true,
                preConfirm: login => {
                    const apiUrl = `${API_URL}restaurant-kot/${kotID}/item/${itemID}`
                    console.log('api url of update kot item status', apiUrl);
                    const bodyData = JSON.stringify({
                        status: isFromCooking ? "done" : "cooking"
                    });

                    console.log('bodyData of kot status update api', bodyData)
                    return fetch(apiUrl, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                        body: bodyData
                    }).then((res) => {
                        console.log('response of update status api', res)
                        const title = isFromCooking ? strings.completed : strings.accepted;
                        const message = isFromCooking ? strings.item_complete_msg : strings.item_accept_msg;
                        if (res.status === 200) {
                            swal.fire(
                                title,
                                message,
                                "success"
                            );
                            setLoading(true);
                            getCurrentKOTOrderList();
                        } else {
                            swal.showValidationMessage(`Something went wrong!`);
                        }
                        return res.json();
                    }).catch((err) => {
                        swal.showValidationMessage(`Request failed: ${err}`);
                    })
                }
            })
            .then(result => {
                console.log('result of update status', result);
            });
    }


    return (
        <>
            <KOTOrderList
                loading={loading}
                records={records}
                limit={limit}
                config={config}
                handleStatus={handleStatus}
                isCurrentOrders
            />
        </>
    )
}
export default CurrentKOTOrders